import { useState, useEffect } from "react";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import SnippetFolderOutlinedIcon from "@mui/icons-material/SnippetFolderOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import DeleteFileDialog from "../../components/DeleteFileDialog";
import DeleteClientDialog from "../../components/DeleteClientDialog";
import Alert from "@mui/material/Alert";
import minMax from "dayjs/plugin/minMax";

import { red } from "@mui/material/colors";

import {
  Box,
  TextField,
  useTheme,
  Button,
  Typography,
  useMediaQuery,
  Paper,
  IconButton,
  Autocomplete,
  Chip,
  Stack,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import useAxiosPrivate from "../../api/useAxiosPrivate";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { summarizeClientTransactions } from "../transactions/dataProcessing";
dayjs.extend(minMax);

const EditClient = (props) => {
  const formData = new FormData();
  const {
    handleClose,
    setAlertMessage,
    setOpenAlert,
    setSeverity,
    clientInfo,
    stores,
  } = props;
  const [store, setStore] = useState(stores[0]);
  const [tableViewSelect, setTableViewSelect] = useState("Transactions");
  const [hasTransactions, setHasTransactions] = useState(true);

  // Configurações
  const EDIT_CLIENT_URL = `/clients/${clientInfo.id}`;
  const axiosPrivate = useAxiosPrivate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let USDollar = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 });

  const { t } = useTranslation();

  //Informações Selecionáveis
  const [openDeleteFileDialog, setOpenDeleteFileDialog] = useState(false);
  const [fileInfo, setFileInfo] = useState({});
  const [editMode, setEditMode] = useState(false);

  const [clientTransactionsTotal, setClientTransactionsTotal] = useState([]);
  const [clientTransactionsByProvider, setClientTransactionsByProvider] =
    useState([]);
  const [clientLimitTransaction, setClientLimitTransaction] = useState([]);

  const [name, setName] = useState(clientInfo.name);
  const [phoneNumber, setPhoneNumber] = useState(clientInfo.phoneNumber);
  const [birthDate, setBirthDate] = useState(
    clientInfo.birthDate ? dayjs(clientInfo.birthDate) : null
  );
  const [email, setEmail] = useState(clientInfo.email);
  const [address, setAddress] = useState(clientInfo.address);
  const [city, setCity] = useState(clientInfo.city);
  const [state, setState] = useState(clientInfo.state);
  const [zipCode, setZipCode] = useState(clientInfo.zipCode);
  const [country, setCountry] = useState(clientInfo.country);

  const [documentType, setDocumentType] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [documentExpiration, setDocumentExpiration] = useState(dayjs());
  const [proofOfFundsType, setProofOfFundsType] = useState("");
  const [proofOfFundsName, setProofOfFundsName] = useState("");
  const [proofOfFundsExpiration, setProofOfFundsExpiration] = useState(dayjs());

  const [passportNumber, setPassportNumber] = useState(
    clientInfo.passportNumber
  );
  const [passportExpiration, setPassportExpiration] = useState(
    clientInfo.passportExpiration ? dayjs(clientInfo.passportExpiration) : null
  );

  const [otherInformations, setOtherInformations] = useState(
    clientInfo.otherInformations || ""
  );

  const [driverLicense, setDriverLicense] = useState(clientInfo.driverLicense);
  const [driverLicenseExpiration, setDriverLicenseExpiration] = useState(
    clientInfo.driverLicenseExpiration
      ? dayjs(clientInfo.driverLicenseExpiration)
      : null
  );

  const [SSN_ITIN, setSSN_ITIN] = useState(clientInfo.SSN_ITIN);

  const [selectedDocumentFiles, setSelectedDocumentFiles] = useState([]);
  const [selectedProofOfFundsFiles, setSelectedProofOfFundsFiles] = useState(
    []
  );
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [documentFiles, setDocumentFiles] = useState(
    clientInfo.files.filter((file) => file.fileOrigin === "Document")
  );
  const [proofOfFundsFiles, setProofOfFundsFiles] = useState(
    clientInfo.files.filter((file) => file.fileOrigin === "Proof of Funds")
  );

  const [recipients, setRecipients] = useState(clientInfo.recipients);
  const [openDeleteClientDialog, setOpenDeleteClientDialog] = useState(false);

  useEffect(() => {
    const getTransactions = async () => {
      var storeProviders = [];

      try {
        const response = await axiosPrivate.get("/storeProvider/storeChain");

        storeProviders = response.data.filter(
          (provider) => provider.storeId === stores[0].id
        );
      } catch (err) {}

      try {
        const response = await axiosPrivate.get(
          `/transactions/client/${clientInfo.id}`
        );

        if (response.data.length === 0) setHasTransactions(false);

        summarizeClientTransactions(
          response.data.filter(
            (transaction) => transaction.storeId === stores[0].id
          ),
          storeProviders,
          setClientTransactionsByProvider,
          setClientTransactionsTotal,
          setClientLimitTransaction
        );
      } catch (err) {}
    };

    getTransactions();
  }, []);

  useEffect(() => {
    const getFiles = async () => {
      try {
        const response = await axiosPrivate.get(`/clients/${clientInfo.id}`);
        const files = response.data?.files;

        if (files) {
          setProofOfFundsFiles(
            files.filter((file) => file.fileOrigin === "Proof of Funds")
          );
          setDocumentFiles(
            files.filter((file) => file.fileOrigin === "Document")
          );
        }
      } catch (err) {}
    };
    getFiles();
  }, [openDeleteFileDialog]);

  const handleCloseDeleteFileDialog = () => {
    setOpenDeleteFileDialog(false);
  };

  const changeTransactionTableView = () => {
    setTableViewSelect("Transactions");
  };

  const changeLimitsTableView = () => {
    setTableViewSelect("Limits");
  };
  const handleOpenDeleteClientDialog = () => {
    setOpenDeleteClientDialog(true);
  };

  const handleCloseDeleteClientDialog = () => {
    setOpenDeleteClientDialog(false);
  };

  const handleProofFundsFileChange = (event) => {
    const files = event.target.files;

    Array.from(files).forEach((file) => {
      if (file.size / 1024 / 1024 >= 4) {
        setAlertMessage("O Arquivo deve ter no máximo 4MB");
        setSeverity("warning");
        setOpenAlert(true);
        setProofOfFundsFiles([]);
      } else {
        setSelectedProofOfFundsFiles(files);
        setProofOfFundsName(files[0].name);
      }
    });
  };

  const handleDocumentFileChange = (event) => {
    const files = event.target.files;

    Array.from(files).forEach((file) => {
      if (file.size / 1024 / 1024 >= 4) {
        setAlertMessage("O Arquivo deve ter no máximo 4MB");
        setSeverity("warning");
        setOpenAlert(true);
        setDocumentFiles([]);
      } else {
        setSelectedDocumentFiles(files);
        setDocumentName(files[0].name);
      }
    });
  };

  const handleUploadDocumentFile = async (e) => {
    e.preventDefault();
    setLoadingFiles(true);
    const fileFormData = new FormData();
    const fileOrigin = "Document";

    fileFormData.append("files", selectedDocumentFiles[0]);
    fileFormData.append("fileOrigin", fileOrigin);
    fileFormData.append("expirationDate", documentExpiration);
    fileFormData.append("documentType", documentType);
    fileFormData.append("name", documentName);

    try {
      const response = await axiosPrivate.putForm(
        `/clients/documents/${clientInfo.id}`,
        fileFormData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          credentials: "include",
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setAlertMessage(`Arquivo adicionado: ${response.data.fileName}`);
        setSeverity("success");
        setOpenAlert(true);
        setDocumentFiles([...documentFiles, response.data]);
        setLoadingFiles(false);
        setSelectedDocumentFiles([]);
        setDocumentName("");
        setDocumentType("");
        setDocumentExpiration(dayjs());
        //handleClose();
      }
    } catch (err) {
      if (!err?.response) {
        setAlertMessage(t("clients.editClient.serverFailAlertMessage"));
        setSeverity("error");
        setOpenAlert(true);
      } else if (err.response?.status === 400) {
        setAlertMessage(
          err.response?.data.message ||
            t("clients.editClient.serverFailAlertMessage")
        );
        setSeverity("error");
        setOpenAlert(true);
      } else {
        setAlertMessage(t("clients.editClient.otherFailAlertMessage"));
        setSeverity("error");
        setOpenAlert(true);
      }
    }
  };

  const handleUploadProofOfFundsFile = async (e) => {
    e.preventDefault();
    setLoadingFiles(true);
    const fileFormData = new FormData();
    const fileOrigin = "Proof of Funds";

    fileFormData.append("files", selectedProofOfFundsFiles[0]);
    fileFormData.append("fileOrigin", fileOrigin);
    fileFormData.append("expirationDate", proofOfFundsExpiration);
    fileFormData.append("documentType", proofOfFundsType);
    fileFormData.append("name", proofOfFundsName);

    try {
      const response = await axiosPrivate.putForm(
        `/clients/documents/${clientInfo.id}`,
        fileFormData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          credentials: "include",
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setAlertMessage(
          `Prova de Fundos adicionada: ${response.data.fileName}`
        );
        setSeverity("success");
        setOpenAlert(true);
        setProofOfFundsFiles([...proofOfFundsFiles, response.data]);
        setLoadingFiles(false);
        setSelectedProofOfFundsFiles([]);
        setProofOfFundsName("");
        setProofOfFundsType("");
        setProofOfFundsExpiration(dayjs());
        //handleClose();
      }
    } catch (err) {
      if (!err?.response) {
        setAlertMessage(t("clients.editClient.serverFailAlertMessage"));
        setSeverity("error");
        setOpenAlert(true);
      } else if (err.response?.status === 400) {
        setAlertMessage(
          err.response?.data.message ||
            t("clients.editClient.serverFailAlertMessage")
        );
        setSeverity("error");
        setOpenAlert(true);
      } else {
        setAlertMessage(t("clients.editClient.otherFailAlertMessage"));
        setSeverity("error");
        setOpenAlert(true);
      }
    }
  };

  const handleEditCancel = () => {
    setEditMode(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack

    if (!name) {
      setAlertMessage(t("clients.editClient.alertMessage1"));
      setSeverity("warning");
      setOpenAlert(true);
      return;
    }

    setLoadingFiles(true);

    formData.append("name", name);
    if (phoneNumber) formData.append("phoneNumber", phoneNumber);
    if (birthDate)
      formData.append("birthDate", dayjs(birthDate).format("YYYY-MM-DD"));
    if (email) formData.append("email", email);
    if (address) formData.append("address", address);
    if (city) formData.append("city", city);
    if (state) formData.append("state", state);
    if (zipCode) formData.append("zipCode", zipCode);
    if (country) formData.append("country", country);

    if (passportNumber) formData.append("passportNumber", passportNumber);
    if (passportExpiration) {
      formData.append(
        "passportExpiration",
        dayjs(passportExpiration).format("YYYY-MM-DD")
      );
    }
    if (driverLicense) formData.append("driverLicense", driverLicense);
    if (driverLicenseExpiration) {
      formData.append(
        "driverLicenseExpiration",
        dayjs(driverLicenseExpiration).format("YYYY-MM-DD")
      );
    }
    if (SSN_ITIN) formData.append("SSN_ITIN", SSN_ITIN);
    if (otherInformations)
      formData.append("otherInformations", otherInformations);

    try {
      const response = await axiosPrivate.put(EDIT_CLIENT_URL, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        credentials: "include",
        withCredentials: true,
      });

      if (response.status === 200) {
        setAlertMessage(
          `${t("clients.editClient.successAlertMessage")}: ${
            response.data.name
          }`
        );
        setSeverity("success");
        setOpenAlert(true);
        handleClose();
      }
    } catch (err) {
      if (!err?.response) {
        setAlertMessage(t("clients.editClient.serverFailAlertMessage"));
        setSeverity("error");
        setOpenAlert(true);
      } else if (err.response?.status === 400) {
        setAlertMessage(
          err.response?.data.message ||
            t("clients.editClient.serverFailAlertMessage")
        );
        setSeverity("error");
        setOpenAlert(true);
      } else {
        setAlertMessage(t("clients.editClient.otherFailAlertMessage"));
        setSeverity("error");
        setOpenAlert(true);
      }
    }
  };

  const getTransactions = async (clientId, storeId) => {
    var storeProviders = [];

    try {
      const response = await axiosPrivate.get("/storeProvider/storeChain");

      storeProviders = response.data.filter(
        (provider) => provider.storeId === storeId
      );
    } catch (err) {}

    try {
      const response = await axiosPrivate.get(
        `/transactions/client/${clientId}`
      );

      summarizeClientTransactions(
        response.data.filter((transaction) => transaction.storeId === storeId),
        storeProviders,
        setClientTransactionsByProvider,
        setClientTransactionsTotal,
        setClientLimitTransaction
      );
    } catch (err) {}
  };

  return (
    <Box>
      <Header
        title={`${clientInfo.name}`}
        subtitle={
          editMode
            ? t("clients.editClient.header.title")
            : t("clients.viewClient.header.title")
        }
        color="#004549"
      />
      {dayjs.max(
        clientInfo?.files
          .filter((file) => file.fileOrigin === "Document")
          .map((obj) => dayjs(obj.expirationDate))
      ) < dayjs() && (
        <Alert severity="warning" sx={{ mb: "12px" }}>
          Este cliente está com o Documento Vencido ou sem Documentos.
        </Alert>
      )}

      {dayjs.max(
        clientInfo?.files
          .filter((file) => file.fileOrigin === "Proof of Funds")
          .map((obj) => dayjs(obj.expirationDate))
      ) < dayjs() &&
      clientInfo?.files.filter((file) => file.fileOrigin === "Proof of Funds")
        .length > 0 ? (
        <Alert severity="warning" sx={{ mb: "12px" }}>
          Este cliente está com a Prova de Fundos Vencida.
        </Alert>
      ) : (
        <></>
      )}

      {Math.min(...clientLimitTransaction.map((provider) => provider.limit)) <
        0 &&
      dayjs.max(
        clientInfo?.files
          .filter((file) => file.fileOrigin === "Proof of Funds")
          .map((obj) => dayjs(obj.expirationDate))
      ) < dayjs() ? (
        <Alert severity="warning" sx={{ mb: "12px" }}>
          Devido ao histórico de envios deste cliente, recomenda-se solicitar
          Provas de Fundos.
        </Alert>
      ) : (
        <></>
      )}

      <Box
        borderRadius="4px"
        padding="8px"
        backgroundColor={colors.grey[100]}
        display="flex"
        justifyContent="flex-end"
        gap="8px"
        gridTemplateColumns="repeat(4, 1fr)"
        sx={{
          "& > div": { gridColumns: "span 4" },
        }}
      >
        {!editMode ? (
          <>
            <Button
              sx={{
                backgroundColor: colors.primary[500],
                color: colors.grey[100],
                "&:hover": {
                  backgroundColor: colors.primary[400],
                  color: "white",
                },
                width: "200px",
              }}
              id="edit-client"
              onClick={() => setEditMode(true)}
            >
              <ModeEditOutlineOutlinedIcon sx={{ mr: "10px" }} />
              <Typography fontWeight="bold">
                {t("clients.viewClient.body.editClientButton")}
              </Typography>
            </Button>
            {!hasTransactions && (
              <Button
                sx={{
                  backgroundColor: colors.grey[900],
                  color: colors.grey[100],
                  "&:hover": {
                    backgroundColor: colors.grey[800],
                    color: "white",
                  },
                  width: "20px",
                }}
                id="edit-client"
                onClick={handleOpenDeleteClientDialog}
              >
                <DeleteForeverOutlinedIcon />
              </Button>
            )}
          </>
        ) : (
          <Button
            sx={{
              backgroundColor: red[700],
              color: colors.grey[100],
              "&:hover": {
                backgroundColor: red[600],
                color: "white",
              },
              width: "200px",
            }}
            id="edit-client"
            onClick={handleEditCancel}
          >
            <ModeEditOutlineOutlinedIcon sx={{ mr: "10px" }} />
            <Typography fontWeight="bold">
              {t("clients.viewClient.body.cancelEditClientButton")}
            </Typography>
          </Button>
        )}
      </Box>

      <Box
        borderRadius="4px"
        marginTop="12px"
        padding="8px"
        backgroundColor={colors.grey[100]}
        display="grid"
        gap="8px"
        gridTemplateColumns="repeat(6, 1fr)"
        sx={{
          "& > div": { gridColumns: "span 6" },
        }}
      >
        <TextField
          id="name"
          label={t("clients.form.name")}
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          inputProps={{ readOnly: editMode ? false : true }}
          sx={{
            gridColumn: isNonMobile ? "span 4" : "span 3",
            backgroundColor: editMode ? colors.primary[100] : colors.grey[100],
          }}
        />
        <TextField
          id="phoneNumber"
          label={t("clients.form.phoneNumber")}
          variant="outlined"
          value={[phoneNumber]}
          onChange={(e) => setPhoneNumber(e.target.value)}
          required
          inputProps={{ readOnly: editMode ? false : true }}
          sx={{
            gridColumn: isNonMobile ? "span 2" : "span 3",
            backgroundColor: editMode ? colors.primary[100] : colors.grey[100],
          }}
        />
        <TextField
          id="email"
          label={t("clients.form.email")}
          variant="outlined"
          value={email}
          inputProps={{ readOnly: editMode ? false : true }}
          onChange={(e) => setEmail(e.target.value)}
          sx={{
            gridColumn: isNonMobile ? "span 4" : "span 3",
            backgroundColor: editMode ? colors.primary[100] : colors.grey[100],
          }}
        />
        <DatePicker
          id="birthDate"
          readOnly={!editMode}
          variant="outlined"
          disableFuture
          label={t("clients.form.birthDate")}
          value={birthDate}
          onChange={(newValue) => setBirthDate(newValue)}
          sx={{
            gridColumn: isNonMobile ? "span 2" : "span 3",
            backgroundColor: editMode ? colors.primary[100] : colors.grey[100],
          }}
          format="YYYY-MM-DD"
        />
        <TextField
          id="address"
          inputProps={{ readOnly: editMode ? false : true }}
          label={t("clients.form.address")}
          variant="outlined"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          sx={{
            gridColumn: isNonMobile ? "span 4" : "span 3",
            backgroundColor: editMode ? colors.primary[100] : colors.grey[100],
          }}
        />

        <TextField
          id="zipCode"
          inputProps={{ readOnly: editMode ? false : true }}
          label={t("clients.form.zipCode")}
          variant="outlined"
          value={zipCode}
          onChange={(e) => setZipCode(e.target.value)}
          sx={{
            gridColumn: isNonMobile ? "span 2" : "span 3",
            backgroundColor: editMode ? colors.primary[100] : colors.grey[100],
          }}
        />
        <TextField
          id="city"
          inputProps={{ readOnly: editMode ? false : true }}
          label={t("clients.form.city")}
          variant="outlined"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          sx={{
            gridColumn: "span 2",
            backgroundColor: editMode ? colors.primary[100] : colors.grey[100],
          }}
        />
        <TextField
          id="state"
          inputProps={{ readOnly: editMode ? false : true }}
          label={t("clients.form.state")}
          variant="outlined"
          value={state}
          onChange={(e) => setState(e.target.value)}
          sx={{
            gridColumn: "span 2",
            backgroundColor: editMode ? colors.primary[100] : colors.grey[100],
          }}
        />
        <TextField
          id="country"
          inputProps={{ readOnly: editMode ? false : true }}
          label={t("clients.form.country")}
          variant="outlined"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          sx={{
            gridColumn: "span 2",
            backgroundColor: editMode ? colors.primary[100] : colors.grey[100],
          }}
        />
        <TextField
          id="passportNumber"
          inputProps={{ readOnly: editMode ? false : true }}
          label={t("clients.form.passportNumber")}
          variant="outlined"
          value={passportNumber}
          onChange={(e) => setPassportNumber(e.target.value)}
          sx={{
            gridColumn: isNonMobile ? "span 2" : "span 3",
            backgroundColor: editMode ? colors.primary[100] : colors.grey[100],
          }}
        />
        <DatePicker
          id="passportExpiration"
          readOnly={!editMode}
          disabled={!passportNumber}
          label={t("clients.form.passportExpiration")}
          variant="outlined"
          value={passportExpiration}
          onChange={(e) => setPassportExpiration(e)}
          sx={{
            gridColumn: isNonMobile ? "span 1" : "span 3",
            backgroundColor: editMode ? colors.primary[100] : colors.grey[100],
          }}
          format="YYYY-MM-DD"
        />
        <TextField
          id="driverLicense"
          inputProps={{ readOnly: editMode ? false : true }}
          label="Driver License"
          variant="outlined"
          value={driverLicense}
          onChange={(e) => setDriverLicense(e.target.value)}
          sx={{
            gridColumn: isNonMobile ? "span 2" : "span 3",
            backgroundColor: editMode ? colors.primary[100] : colors.grey[100],
          }}
        />
        <DatePicker
          id="driverLicenseExpiration"
          readOnly={!editMode}
          label="Validade da Driver License"
          disabled={!driverLicense}
          variant="outlined"
          value={driverLicenseExpiration}
          onChange={(e) => setDriverLicenseExpiration(e)}
          sx={{
            gridColumn: isNonMobile ? "span 1" : "span 3",
            backgroundColor: editMode ? colors.primary[100] : colors.grey[100],
          }}
          format="YYYY-MM-DD"
        />
        <TextField
          id="SSN_ITIN"
          inputProps={{ readOnly: editMode ? false : true }}
          label="SSN/ITIN"
          variant="outlined"
          value={SSN_ITIN}
          onChange={(e) => setSSN_ITIN(e.target.value)}
          sx={{
            gridColumn: isNonMobile ? "span 3" : "span 6",
            backgroundColor: editMode ? colors.primary[100] : colors.grey[100],
          }}
        />
      </Box>
      {!editMode && (
        <Box
          borderRadius="4px"
          marginTop="12px"
          padding="8px"
          backgroundColor={colors.grey[100]}
          display="grid"
          gap="4px"
          gridTemplateColumns="repeat(20, 1fr)"
          sx={{
            "& > div": { gridColumns: "span 20" },
          }}
        >
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ gridColumn: "span 20" }}
          >
            {t("clients.viewClient.body.transactionSectionHeader")}
          </Typography>

          <Autocomplete
            fullWidth
            className="autocomplete"
            required
            value={store}
            disableClearable
            variant="outlined"
            id="store"
            options={stores}
            getOptionLabel={(option) => option.name}
            sx={{
              gridColumn: "span 10",
            }}
            onChange={(_, newValue) => {
              setStore(newValue);
              getTransactions(clientInfo.id, newValue.id);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label={t(
                  "clients.viewClient.body.transactionSectionTable.store"
                )}
              />
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />

          <Box
            display="flex"
            justifyContent="space-around"
            gridColumn={"span 10"}
          >
            {clientTransactionsByProvider.length > 0 ? (
              <Stack direction="row" spacing={1}>
                <Chip
                  label="Transações"
                  onClick={changeTransactionTableView}
                  sx={{
                    backgroundColor:
                      tableViewSelect === "Transactions"
                        ? colors.primary[500]
                        : colors.grey[200],
                    color:
                      tableViewSelect === "Transactions"
                        ? colors.grey[100]
                        : colors.primary[900],
                  }}
                />
                <Chip
                  label="Alertas de Documentação"
                  onClick={changeLimitsTableView}
                  sx={{
                    backgroundColor:
                      tableViewSelect === "Limits"
                        ? colors.primary[500]
                        : colors.grey[200],
                    color:
                      tableViewSelect === "Limits"
                        ? colors.grey[100]
                        : colors.primary[900],
                  }}
                />
              </Stack>
            ) : (
              <></>
            )}
          </Box>
          <Box
            display="flex"
            justifyContent="space-around"
            gap="20px"
            sx={{ gridColumn: "span 20" }}
          >
            {clientTransactionsByProvider.length < 1 ? (
              <Typography sx={{ gridColumn: "span 20" }}>
                {t(
                  "clients.viewClient.body.transactionSectionTable.noTransactions"
                )}
              </Typography>
            ) : tableViewSelect === "Transactions" ? (
              <TableContainer
                component={Paper}
                sx={{ gridColumn: "span 20", maxHeight: 200 }}
              >
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        <b>
                          {t(
                            "clients.viewClient.body.transactionSectionTable.provider"
                          )}
                        </b>
                      </TableCell>
                      <TableCell align="left">
                        <b>
                          {t(
                            "clients.viewClient.body.transactionSectionTable.week"
                          )}
                        </b>
                      </TableCell>
                      <TableCell align="left">
                        <b>
                          {t(
                            "clients.viewClient.body.transactionSectionTable.month"
                          )}
                        </b>
                      </TableCell>
                      <TableCell align="left">
                        <b>
                          {t(
                            "clients.viewClient.body.transactionSectionTable.year"
                          )}
                        </b>
                      </TableCell>
                      <TableCell align="left">
                        <b>
                          {t(
                            "clients.viewClient.body.transactionSectionTable.total"
                          )}
                        </b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {clientTransactionsByProvider.map((row) => (
                      <TableRow
                        key={row.providerName}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{row.providerName}</TableCell>
                        <TableCell>{`USD ${USDollar.format(
                          row.last7Days
                        )}`}</TableCell>
                        <TableCell>{`USD ${USDollar.format(
                          row.last30Days
                        )}`}</TableCell>
                        <TableCell>{`USD ${USDollar.format(
                          row.lastYear
                        )}`}</TableCell>
                        <TableCell>{`USD ${USDollar.format(
                          row.total
                        )}`}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        <b>TOTAL</b>
                      </TableCell>
                      <TableCell align="left">
                        <b>{`USD ${USDollar.format(
                          clientTransactionsTotal.last7Days
                        )}`}</b>
                      </TableCell>
                      <TableCell align="left">
                        <b>{`USD ${USDollar.format(
                          clientTransactionsTotal.last30Days
                        )}`}</b>
                      </TableCell>
                      <TableCell align="left">
                        <b>{`USD ${USDollar.format(
                          clientTransactionsTotal.lastYear
                        )}`}</b>
                      </TableCell>
                      <TableCell align="left">
                        <b>{`USD ${USDollar.format(
                          clientTransactionsTotal.total
                        )}`}</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>
            ) : (
              <Box disply="flex" flexDirection="column" width="100%">
                <Box p="4px">
                  <p>
                    Esses valores indicam o ponto a partir do qual é necessário
                    solicitar documentação adicional para atender as
                    regulamentaçōes. Use-os para garantir a conformidade em suas
                    transações. Para mais informaçōes{" "}
                    <a
                      style={{
                        color: colors.primary[500],
                        "&:visited": {
                          color: colors.primary[700],
                        },
                      }}
                      href="https://msbmanagerkb.tawk.help/pt-br/article/historico-de-remessas-e-alertas-de-documentacao"
                      target="_blank"
                    >
                      clique aqui
                    </a>{" "}
                    para entender melhor como usar essas informaçōes para deixar
                    sua loja mais segura{" "}
                  </p>
                </Box>
                <TableContainer
                  component={Paper}
                  sx={{ gridColumn: "span 8", maxHeight: 200 }}
                >
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          <b>
                            {t(
                              "clients.viewClient.body.transactionSectionTable.provider"
                            )}
                          </b>
                        </TableCell>
                        <TableCell align="left">
                          <b>Alerta de Documentação</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {clientLimitTransaction.map((row) => (
                        <TableRow
                          key={row.provider}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell>{row.provider}</TableCell>
                          <TableCell>{`USD ${USDollar.format(
                            row.limit
                          )}`}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography p="4px">
                  As companhias de remessa se reservam o direito de solicitar
                  documentos adicionais a qualquer momento, independentemente do
                  valor da remessa.
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      )}
      <Box
        borderRadius="4px"
        marginTop="12px"
        padding="8px"
        backgroundColor={colors.grey[100]}
        display="grid"
        gap="4px"
        gridTemplateColumns="repeat(20, 1fr)"
        sx={{
          "& > div": { gridColumns: "span 20" },
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ gridColumn: "span 20" }}
        >
          Outras Informações
        </Typography>

        <TextField
          id="otherInformations"
          inputProps={{ readOnly: editMode ? false : true }}
          size="small"
          label="Outras Informações"
          variant="outlined"
          value={otherInformations}
          onChange={(e) => setOtherInformations(e.target.value)}
          multiline
          sx={{
            gridColumn: "span 20",
            backgroundColor: editMode ? colors.primary[100] : colors.grey[100],
          }}
        />
      </Box>
      <Box
        borderRadius="4px"
        marginTop="12px"
        padding="8px"
        backgroundColor={colors.grey[100]}
        display="grid"
        gap="4px"
        gridTemplateColumns="repeat(20, 1fr)"
        sx={{
          "& > div": { gridColumns: "span 20" },
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ gridColumn: "span 20" }}
        >
          Beneficiários
        </Typography>

        {recipients.length > 0 ? (
          <TableContainer
            component={Paper}
            sx={{ gridColumn: "span 20", maxHeight: 200 }}
          >
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <b>Nome do Beneficiário</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Outras Informações</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {recipients.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.otherInformations}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <p>Não há Beneficiários Cadastrados</p>
        )}
      </Box>
      <Box
        borderRadius="4px"
        marginTop="12px"
        padding="8px"
        backgroundColor={colors.grey[100]}
        display="grid"
        gap="4px"
        gridTemplateColumns="repeat(20, 1fr)"
        sx={{
          "& > div": { gridColumns: "span 20" },
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ gridColumn: "span 20" }}
        >
          {t("clients.viewClient.body.documentSectionHeader")}
        </Typography>
        {documentFiles.length > 0 ? (
          <TableContainer
            component={Paper}
            sx={{ gridColumn: "span 20", maxHeight: 200 }}
          >
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <b>
                      {t(
                        "clients.viewClient.body.documentSectionTable.fileName"
                      )}
                    </b>
                  </TableCell>
                  <TableCell align="left">
                    <b>
                      {t(
                        "clients.viewClient.body.documentSectionTable.fileType"
                      )}
                    </b>
                  </TableCell>
                  <TableCell align="left">
                    <b>
                      {t(
                        "clients.viewClient.body.documentSectionTable.expirationDate"
                      )}
                    </b>
                  </TableCell>
                  <TableCell align="left">
                    <b>
                      {t(
                        "clients.viewClient.body.documentSectionTable.options"
                      )}
                    </b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {documentFiles.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell>{row.fileName}</TableCell>
                    <TableCell>{row.documentType || ""}</TableCell>
                    <TableCell>
                      {row.expirationDate
                        ? dayjs(row.expirationDate).format("YYYY-MM-DD")
                        : ""}
                    </TableCell>
                    <TableCell>
                      <IconButton href={row.url}>
                        <CloudDownloadOutlinedIcon />
                      </IconButton>
                      {editMode && (
                        <IconButton
                          onClick={() => {
                            setFileInfo(row);
                            setOpenDeleteFileDialog(true);
                          }}
                        >
                          <DeleteForeverOutlinedIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box sx={{ gridColumn: "span 20" }}>
            {" "}
            <Typography>
              {t("clients.viewClient.body.documentSectionTable.noDocuments")}
            </Typography>
          </Box>
        )}
        <Box
          mt="8px"
          sx={{ gridColumn: "span 20" }}
          display="grid"
          gap="4px"
          gridTemplateColumns="repeat(20, 1fr)"
        >
          <Button
            variant="contained"
            component="label"
            disabled={loadingFiles}
            sx={{
              backgroundColor: colors.primary[500],
              color: colors.grey[100],
              "&:hover": {
                backgroundColor: colors.primary[600],
                color: "white",
              },
              gridColumn: "span 1",
            }}
          >
            <SnippetFolderOutlinedIcon />
            <input
              type="file"
              name="files"
              hidden
              onChange={handleDocumentFileChange}
            />
          </Button>
          <TextField
            id="newDocumentName"
            size="small"
            label={t("clients.viewClient.body.documentSectionTable.fileName")}
            disabled={loadingFiles || selectedDocumentFiles.length === 0}
            variant="outlined"
            value={documentName}
            onChange={(e) => setDocumentName(e.target.value)}
            sx={{
              gridColumn: "span 6",
              backgroundColor:
                selectedDocumentFiles.length > 0
                  ? colors.primary[100]
                  : colors.grey[100],
            }}
          />
          <TextField
            id="newDocumentType"
            disabled={loadingFiles || selectedDocumentFiles.length === 0}
            inputProps={{
              readOnly: selectedDocumentFiles.length < 1 ? true : false,
            }}
            size="small"
            label={t("clients.viewClient.body.documentSectionTable.fileType")}
            variant="outlined"
            value={documentType}
            onChange={(e) => setDocumentType(e.target.value)}
            sx={{
              gridColumn: "span 5",
              backgroundColor:
                selectedDocumentFiles.length > 0
                  ? colors.primary[100]
                  : colors.grey[100],
            }}
          />
          <DatePicker
            id="documentExpiration"
            label={t(
              "clients.viewClient.body.documentSectionTable.expirationDate"
            )}
            variant="outlined"
            defaultValue={dayjs()}
            value={documentExpiration}
            onChange={(e) => setDocumentExpiration(e)}
            slotProps={{ textField: { size: "small" } }}
            disabled={loadingFiles || selectedDocumentFiles.length === 0}
            sx={{
              gridColumn: "span 6",
              backgroundColor:
                selectedDocumentFiles.length > 0
                  ? colors.primary[100]
                  : colors.grey[100],
            }}
            format="YYYY-MM-DD"
          />
          <Button
            sx={{
              backgroundColor: colors.primary[500],
              color: colors.grey[100],
              "&:hover": {
                backgroundColor: colors.primary[600],
                color: "white",
              },
              gridColumn: "span 2",
            }}
            disabled={!selectedDocumentFiles.length > 0 || loadingFiles}
            onClick={handleUploadDocumentFile}
          >
            <CloudUploadOutlinedIcon />
          </Button>
        </Box>
      </Box>
      <Box
        borderRadius="4px"
        marginTop="12px"
        padding="8px"
        backgroundColor={colors.grey[100]}
        display="grid"
        gap="4px"
        gridTemplateColumns="repeat(20, 1fr)"
        sx={{
          "& > div": { gridColumns: "span 20" },
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ gridColumn: "span 20" }}
        >
          {t("clients.viewClient.body.proofOfFundsSectionHeader")}
        </Typography>
        {proofOfFundsFiles.length > 0 ? (
          <TableContainer
            component={Paper}
            sx={{ gridColumn: "span 20", maxHeight: 200 }}
          >
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <b>
                      {t(
                        "clients.viewClient.body.proofOfFundsSectionTable.fileName"
                      )}
                    </b>
                  </TableCell>
                  <TableCell align="left">
                    <b>
                      {t(
                        "clients.viewClient.body.proofOfFundsSectionTable.fileType"
                      )}
                    </b>
                  </TableCell>
                  <TableCell align="left">
                    <b>
                      {t(
                        "clients.viewClient.body.proofOfFundsSectionTable.expirationDate"
                      )}
                    </b>
                  </TableCell>
                  <TableCell align="left">
                    <b>
                      {t(
                        "clients.viewClient.body.proofOfFundsSectionTable.options"
                      )}
                    </b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {proofOfFundsFiles.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell>{row.fileName}</TableCell>
                    <TableCell>{row.documentType || ""}</TableCell>
                    <TableCell>
                      {row.expirationDate
                        ? dayjs(row.expirationDate).format("YYYY-MM-DD")
                        : ""}
                    </TableCell>
                    <TableCell>
                      <IconButton href={row.url}>
                        <CloudDownloadOutlinedIcon />
                      </IconButton>
                      {editMode && (
                        <IconButton
                          onClick={() => {
                            setFileInfo(row);
                            setOpenDeleteFileDialog(true);
                          }}
                        >
                          <DeleteForeverOutlinedIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box sx={{ gridColumn: "span 20" }}>
            {" "}
            <Typography>
              {t(
                "clients.viewClient.body.proofOfFundsSectionTable.noDocuments"
              )}
            </Typography>
          </Box>
        )}

        <Box
          mt="8px"
          sx={{ gridColumn: "span 20" }}
          display="grid"
          gap="4px"
          gridTemplateColumns="repeat(20, 1fr)"
        >
          <Button
            variant="contained"
            component="label"
            disabled={loadingFiles}
            sx={{
              backgroundColor: colors.primary[500],
              color: colors.grey[100],
              "&:hover": {
                backgroundColor: colors.primary[600],
                color: "white",
              },
              gridColumn: "span 1",
            }}
          >
            <SnippetFolderOutlinedIcon />
            <input
              type="file"
              name="files"
              hidden
              onChange={handleProofFundsFileChange}
            />
          </Button>
          <TextField
            id="newProofOfFundsName"
            size="small"
            label={t(
              "clients.viewClient.body.proofOfFundsSectionTable.fileName"
            )}
            disabled={loadingFiles || selectedProofOfFundsFiles.length === 0}
            variant="outlined"
            value={proofOfFundsName}
            onChange={(e) => setProofOfFundsName(e.target.value)}
            sx={{
              gridColumn: "span 6",
              backgroundColor:
                selectedProofOfFundsFiles.length > 0
                  ? colors.primary[100]
                  : colors.grey[100],
            }}
          />
          <TextField
            id="newProofOfFundsType"
            inputProps={{
              readOnly: editMode || selectedProofOfFundsFiles.length === 0,
            }}
            size="small"
            label={t(
              "clients.viewClient.body.proofOfFundsSectionTable.fileType"
            )}
            variant="outlined"
            value={proofOfFundsType}
            disabled={loadingFiles || selectedProofOfFundsFiles.length === 0}
            onChange={(e) => setProofOfFundsType(e.target.value)}
            sx={{
              gridColumn: "span 5",
              backgroundColor:
                selectedProofOfFundsFiles.length > 0
                  ? colors.primary[100]
                  : colors.grey[100],
            }}
          />
          <DatePicker
            id="proofOfFundsExpiration"
            label={t(
              "clients.viewClient.body.proofOfFundsSectionTable.expirationDate"
            )}
            variant="outlined"
            defaultValue={dayjs()}
            value={proofOfFundsExpiration}
            onChange={(e) => setProofOfFundsExpiration(e)}
            slotProps={{ textField: { size: "small" } }}
            disabled={loadingFiles || selectedProofOfFundsFiles.length === 0}
            sx={{
              gridColumn: "span 6",
              backgroundColor:
                selectedProofOfFundsFiles.length > 0
                  ? colors.primary[100]
                  : colors.grey[100],
            }}
            format="YYYY-MM-DD"
          />
          <Button
            sx={{
              backgroundColor: colors.primary[500],
              color: colors.grey[100],
              "&:hover": {
                backgroundColor: colors.primary[600],
                color: "white",
              },
              gridColumn: "span 2",
            }}
            disabled={!selectedProofOfFundsFiles.length > 0 || loadingFiles}
            onClick={handleUploadProofOfFundsFile}
          >
            <CloudUploadOutlinedIcon />
          </Button>
        </Box>
      </Box>
      <Box
        borderRadius="4px"
        marginTop="12px"
        padding="8px"
        backgroundColor={colors.grey[100]}
        display="grid"
        gap="4px"
        gridTemplateColumns="repeat(20, 1fr)"
        sx={{
          "& > div": { gridColumns: "span 20" },
        }}
      >
        <Box display="flex" gap="8px" justifyContent="flex-end">
          {editMode && (
            <Button
              type="submit"
              disabled={!name || loadingFiles}
              variant="contained"
              height="100%"
              onClick={handleSubmit}
              sx={{
                backgroundColor: colors.primary[500],
                color: colors.grey[100],
                "&:hover": {
                  backgroundColor: colors.primary[600],
                  color: "white",
                },
                gridColumn: isNonMobile ? "span 1" : "span 2",
              }}
            >
              <Typography fontWeight="bold">
                {t("clients.form.updateClientButton")}
              </Typography>
            </Button>
          )}
          <Button
            sx={{
              backgroundColor: red[700],
              color: colors.grey[100],
              "&:hover": {
                backgroundColor: red[600],
                color: "white",
              },
              gridColumn: isNonMobile ? "span 1" : "span 2",
            }}
            variant="contained"
            height="100%"
            onClick={handleClose}
            disabled={loadingFiles}
          >
            {" "}
            <Typography fontWeight="bold">
              {t("clients.form.cancelButton")}
            </Typography>
          </Button>
        </Box>
      </Box>
      <DeleteFileDialog
        openDeleteClientDialog={openDeleteFileDialog}
        handleClose={handleCloseDeleteFileDialog}
        fileInfo={fileInfo}
        setAlertMessage={setAlertMessage}
        setOpenAlert={setOpenAlert}
        setSeverity={setSeverity}
      />
      <DeleteClientDialog
        openDeleteClientDialog={openDeleteClientDialog}
        handleCloseDialog={handleCloseDeleteClientDialog}
        handleCloseEditDialog={handleClose}
        clientInfo={clientInfo}
        setAlertMessage={setAlertMessage}
        setOpenAlert={setOpenAlert}
        setSeverity={setSeverity}
      />
    </Box>
  );
};
export default EditClient;
