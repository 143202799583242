import {
  Box,
  Button,
  TextField,
  useTheme,
  Autocomplete,
  Typography,
  Paper,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useState } from "react";
import { tokens } from "../../theme";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { styled } from "@mui/material/styles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import {
  handleFileAnalysisLP,
  handleFileAnalysisUNO,
  handleFileAnalysisWU,
  handleFileAnalysisRIA,
  handleFileAnalysisGolden,
} from "./findInfoProviders";
import UploadButton from "./UploadButton";

const UploadLedger = (props) => {
  const {
    handleClose,
    stores,
    storeProviders,
    setAlertMessage,
    setOpenAlert,
    setSeverity,
  } = props;

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [store, setStore] = useState(stores[0]);
  const [provider, setProvider] = useState(null);

  const [file, setFile] = useState(null);
  const [excelData, setExcelData] = useState([]);
  const [uploadingFile, setUploadingFile] = useState(false);

  const ColorButton = styled(Button)(() => ({
    color: "white",
    backgroundColor: "#f04646",
    "&:hover": {
      backgroundColor: "#A72323",
    },
  }));

  const typefiles = [
    { provider: "LibertyPay", types: ["xlsx", "xls"] },
    { provider: "Western Union", types: "csv" },
    { provider: "UNO/Omnex", types: ["csv"] },
    { provider: "RIA", types: ["csv"] },
    { provider: "Golden", types: ["csv"] },
  ];

  const columns = [
    "Data",
    "País de Destino",
    "Remetente",
    "Telefone do Remetente",
    "Beneficiário",
    "Valor Enviado USD",
    "Taxa Final USD",
    "Valor Coletado",
    "Método de Pagamento",
  ];

  const handleError = (severity, message) => {
    setAlertMessage(message);
    setSeverity(severity);
    setOpenAlert(true);
    setFile(null);
  };

  const handleFileChange = (event, providerName) => {
    if (event.target.files[0]) {
      if (
        typefiles
          .filter((option) => option.provider === providerName)[0]
          .types.includes(event.target.files[0].name.split(".").pop())
      ) {
        setFile(event.target.files[0]);
        setUploadingFile(true);
        switch (providerName) {
          case "LibertyPay":
            {
              handleFileAnalysisLP(
                event.target.files[0],
                handleError,
                setExcelData,
                setUploadingFile
              );
            }
            break;
          case "UNO/Omnex":
            {
              handleFileAnalysisUNO(
                event.target.files[0],
                handleError,
                setExcelData,
                setUploadingFile
              );
            }
            break;
          case "Western Union":
            {
              handleFileAnalysisWU(
                event.target.files[0],
                handleError,
                setExcelData,
                setUploadingFile
              );
            }
            break;
          case "RIA":
            {
              handleFileAnalysisRIA(
                event.target.files[0],
                handleError,
                setExcelData,
                setUploadingFile
              );
            }
            break;
          case "Golden": {
            handleFileAnalysisGolden(
              event.target.files[0],
              handleError,
              setExcelData,
              setUploadingFile
            );
          }
        }
      } else {
        setAlertMessage(
          `O arquivo da Provedora ${provider.Provider.name} precisa ser ${
            typefiles.filter(
              (option) => option.provider === provider.Provider.name
            )[0].types
          }`
        );
        setSeverity("warning");
        setOpenAlert(true);
      }
    }
  };

  return (
    <Box>
      <Header
        title="UPLOAD REMESSAS"
        subtitle="Envie um export das suas Remessas para carregamento automático"
        button={false}
        color="#9f1853"
      />

      <Box
        borderRadius="10px"
        padding="20px"
        backgroundColor={colors.grey[100]}
        display="grid"
        gap="15px"
        gridTemplateColumns="repeat(6, 1fr)"
        sx={{
          "& > div": { gridColumns: "span 4" },
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ gridColumn: "span 6" }}
        >
          Loja
        </Typography>
        <Autocomplete
          fullWidth
          required
          value={store}
          defaultValue={[stores[0]]}
          disableClearable
          variant="outlined"
          id="country"
          options={stores}
          getOptionLabel={(option) => option.name}
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 6" }}
          onChange={(_, newValue) => {
            setStore(newValue);
            setProvider(null);
            setFile(null);
            setExcelData([]);
          }}
          renderInput={(params) => <TextField {...params} label="Loja" />}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />

        <Autocomplete
          fullWidth
          required
          disabled={!store || !!excelData.length}
          value={provider}
          disableClearable
          variant="outlined"
          id="provider"
          options={storeProviders
            .filter((provider) => provider.storeId === store.id)
            .filter((provider) => provider.active)
            .filter((provider) =>
              typefiles.map((p) => p.provider).includes(provider.Provider.name)
            )}
          getOptionLabel={(option) => option.Provider.name}
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 6" }}
          onChange={(_, newValue) => setProvider(newValue)}
          renderInput={(params) => <TextField {...params} label="Provedora" />}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
        <Button
          variant="contained"
          disabled={!store || !provider || uploadingFile}
          component="label"
          startIcon={<CloudUploadOutlinedIcon />}
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 6" }}
        >
          {" "}
          {uploadingFile
            ? "Carregando Arquivo..."
            : `ANEXAR ARQUIVO ${
                typefiles.filter(
                  (p) => p.provider === provider?.Provider?.name
                )[0]?.types || ""
              }`}
          <input
            type="file"
            hidden
            onChange={(e) => handleFileChange(e, provider.Provider.name)}
          />
        </Button>
        {file && (
          <Typography sx={{ gridColumn: "span 6" }}>
            Arquivo: <b>{file.name}</b>
          </Typography>
        )}
      </Box>

      {!provider && (
        <Typography>
          Caso a provedora que esteja procurando não esteja na lista entre em
          contato com o nosso suporte.{" "}
        </Typography>
      )}

      {!!excelData.length && (
        <Box
          marginTop={"15px"}
          borderRadius="10px"
          padding="20px"
          backgroundColor={colors.grey[100]}
          display="grid"
          gap="15px"
          //gridTemplateColumns="repeat(6, 1fr)"
          sx={{
            "& > div": { gridColumns: "span 6" },
          }}
        >
          <TableContainer
            component={Paper}
            sx={{ gridColumn: "span 8", maxHeight: 200 }}
          >
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow key={"a"}>
                  {columns.map((element) => (
                    <TableCell align="left">
                      <b>{element}</b>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {excelData.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    {Object.keys(row).map((element) => (
                      <TableCell>{row[element]}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      <Box
        marginTop={"15px"}
        borderRadius="10px"
        padding="20px"
        backgroundColor={colors.grey[100]}
        display="flex"
        gap="15px"
        justifyContent="center"
        sx={{
          "& > div": { gridColumns: "span 6" },
        }}
      >
        {!!excelData.length && (
          <UploadButton
            file={excelData}
            originalFile={file}
            store={store}
            provider={provider}
            setAlertMessage={setAlertMessage}
            setSeverity={setSeverity}
            setOpenAlert={setOpenAlert}
            handleClose={handleClose}
          />
        )}
        <ColorButton
          variant="contained"
          height="50%"
          onClick={handleClose}
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 2" }}
        >
          Cancelar
        </ColorButton>
      </Box>
    </Box>
  );
};

export default UploadLedger;
