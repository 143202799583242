import { useState } from "react";
import { styled } from "@mui/material/styles";

import {
  Box,
  TextField,
  useTheme,
  Button,
  Typography,
  FormControl,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import useAxiosPrivate from "../../../api/useAxiosPrivate";
import { NumericFormat } from "react-number-format";
import DeleteStoreProviderDialog from "../../../components/DeleteStoreProviderDialog";

const EDIT_STORE_PROVIDER_URL = "/storeProvider/";

const EditStoreProvider = (props) => {
  const {
    provider_info,
    handleClose,
    stores,
    providers,
    storeProviders,
    setAlertMessage,
    setOpenAlert,
    setSeverity,
  } = props;
  const axiosPrivate = useAxiosPrivate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [openDeleteStoreProviderDialog, setOpenDeleteStoreProviderDialog] =
    useState(false);

  const handleOpenDeleteStoreProviderDialog = () => {
    setOpenDeleteStoreProviderDialog(true);
  };

  const handleCloseDeleteStoreProviderDialog = () => {
    setOpenDeleteStoreProviderDialog(false);
  };

  const [store, setStore] = useState(
    stores.filter((store) => store.id === provider_info.storeId)[0]
  );
  const [provider, setProvider] = useState(
    providers.filter((provider) => provider.id === provider_info.providerId)[0]
  );

  const [customAnnualLimit, setCustomAnnualLimit] = useState(
    provider_info.customAnnualLimit
  );
  const [customMonthlyLimit, setCustomMonthlyLimit] = useState(
    provider_info.customMonthlyLimit
  );
  const [customDailyLimit, setCustomDailyLimit] = useState(
    provider_info.customDailyLimit
  );

  const [active, setActive] = useState(provider_info.active);

  const handleChangeActive = (event) => {
    setActive(event.target.checked);
  };

  const handleChangeProvider = (newValue) => {
    setProvider(newValue);
    setCustomAnnualLimit(newValue.annualLimit);
    setCustomMonthlyLimit(newValue.monthlyLimit);
    setCustomDailyLimit(newValue.dailyLimit);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    var updateInfo = {};

    if (store.id) {
      updateInfo.storeId = store.id;
      updateInfo.providerId = provider.id;
      updateInfo.customAnnualLimit = customAnnualLimit;
      updateInfo.customMonthlyLimit = customMonthlyLimit;
      updateInfo.customDailyLimit = customDailyLimit;
      updateInfo.active = active;
    }

    try {
      const response = await axiosPrivate.put(
        `${EDIT_STORE_PROVIDER_URL}${provider_info.id}`,
        JSON.stringify(updateInfo),
        {
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          withCredentials: true,
        }
      );

      if (response.status === 204) {
        setAlertMessage("Provedora Atualizada");
        setSeverity("success");
        setOpenAlert(true);
        handleClose();
      }
    } catch (err) {
      if (!err?.response) {
        setAlertMessage(
          "O servidor não está respondendo. Por favor tente novamente mais tarde."
        );
        setSeverity("error");
        setOpenAlert(true);
      } else if (err.response?.status === 400) {
        setAlertMessage(err.response?.data.message || "Erro");
        setSeverity("error");
        setOpenAlert(true);
      } else {
        setAlertMessage("Falha no Registro da Provedora");
        setSeverity("error");
        setOpenAlert(true);
      }
    }
  };

  const ColorButton = styled(Button)(() => ({
    color: "white",
    backgroundColor: "#f04646",
    "&:hover": {
      backgroundColor: "#A72323",
    },
  }));

  return (
    <Box>
      <Header
        title="EDITAR PROVEDORA"
        subtitle="Edite as Informações da Provedora"
        button={false}
        color="#621B74"
      />
      <Box
        borderRadius="8px"
        padding="8px"
        backgroundColor={colors.grey[100]}
        display="flex"
        justifyContent={"flex-end"}
      >
        <Button
          variant="contained"
          sx={{ backgroundColor: "black", color: colors.grey[100] }}
          height="100%"
          onClick={handleOpenDeleteStoreProviderDialog}
        >
          {" "}
          <Typography fontWeight="bold">Remover Provedora</Typography>
        </Button>
      </Box>
      <Box
        borderRadius="8px"
        marginTop="12px"
        padding="8px"
        backgroundColor={colors.grey[100]}
        display="grid"
        gap="12px"
        gridTemplateColumns="repeat(8, 1fr)"
      >
        <Box sx={{ gridColumn: "span 8" }} />
        <FormControl sx={{ gridColumn: isNonMobile ? "span 4" : "span 8" }}>
          <Autocomplete
            disabled
            value={store}
            disableClearable
            readOnly
            variant="outlined"
            id="store"
            options={stores}
            getOptionLabel={(option) => option.name}
            //sx={{ gridColumn: "span 1" }}
            onChange={(_, newValue) => setStore(newValue)}
            renderInput={(params) => <TextField {...params} label="Loja" />}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </FormControl>
        <FormControl sx={{ gridColumn: isNonMobile ? "span 4" : "span 8" }}>
          <Autocomplete
            disabled
            value={provider}
            disableClearable
            readOnly
            variant="outlined"
            id="store"
            options={providers}
            getOptionLabel={(option) => option.name}
            //sx={{ gridColumn: "span 1" }}
            onChange={(_, newValue) => handleChangeProvider(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Provedora" />
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </FormControl>
      </Box>
      {provider && (
        <Box
          borderRadius="10px"
          marginTop="20px"
          padding="20px"
          backgroundColor={colors.grey[100]}
          display="grid"
          gap="15px"
          gridTemplateColumns="repeat(8, 1fr)"
        >
          <Box sx={{ gridColumn: "span 8" }}>
            <Typography
              variant="h4"
              fontWeight="bold"
              sx={{ gridColumn: isNonMobile ? "span 4" : "span 8" }}
            >
              Limites Personalizados
            </Typography>
            <Typography
              variant="h7"
              sx={{ gridColumn: isNonMobile ? "span 4" : "span 8" }}
            >
              Você pode selecionar limites diferentes daqueles estipulados pela
              provedora.
            </Typography>
          </Box>
          <NumericFormat
            decimalScale={2}
            thousandSeparator
            fixedDecimalScale
            value={customDailyLimit}
            customInput={TextField}
            required
            id="custom-daily-limit"
            variant="outlined"
            type="text"
            label="Limite Diário Personalizado"
            sx={{ gridColumn: isNonMobile ? "span 4" : "span 8" }}
            onChange={(e) => {
              setCustomDailyLimit(e.target.value.replace(",", ""));
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">USD </InputAdornment>
              ),
              sx: { "& input": { textAlign: "right" } },
            }}
          />
          <NumericFormat
            disabled
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
            value={provider.dailyLimit}
            customInput={TextField}
            id="daily-limit"
            variant="outlined"
            type="text"
            label="Limite Diário da Provedora"
            sx={{ gridColumn: isNonMobile ? "span 4" : "span 8" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">USD </InputAdornment>
              ),
              sx: { "& input": { textAlign: "right" } },
            }}
          />
          <NumericFormat
            decimalScale={2}
            thousandSeparator
            fixedDecimalScale
            value={customMonthlyLimit}
            customInput={TextField}
            required
            id="custom-monthly-limit"
            variant="outlined"
            type="text"
            label="Limite Mensal Personalizado"
            sx={{ gridColumn: isNonMobile ? "span 4" : "span 8" }}
            onChange={(e) => {
              setCustomMonthlyLimit(e.target.value.replace(",", ""));
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">USD </InputAdornment>
              ),
              sx: { "& input": { textAlign: "right" } },
            }}
          />
          <NumericFormat
            disabled
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
            value={provider.monthlyLimit}
            customInput={TextField}
            id="monthly-limit"
            variant="outlined"
            type="text"
            label="Limite Mensal da Provedora"
            sx={{ gridColumn: isNonMobile ? "span 4" : "span 8" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">USD </InputAdornment>
              ),
              sx: { "& input": { textAlign: "right" } },
            }}
          />

          <NumericFormat
            decimalScale={2}
            thousandSeparator
            fixedDecimalScale
            value={customAnnualLimit}
            customInput={TextField}
            required
            id="custom-annual-limit"
            variant="outlined"
            type="text"
            label="Limite Anual Personalizado"
            sx={{ gridColumn: isNonMobile ? "span 4" : "span 8" }}
            onChange={(e) => {
              setCustomAnnualLimit(e.target.value.replace(",", ""));
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">USD </InputAdornment>
              ),
              sx: { "& input": { textAlign: "right" } },
            }}
          />
          <NumericFormat
            disabled
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
            value={provider.annualLimit}
            customInput={TextField}
            id="annual-limit"
            variant="outlined"
            type="text"
            label="Limite Anual da Provedora"
            sx={{ gridColumn: isNonMobile ? "span 4" : "span 8" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">USD </InputAdornment>
              ),
              sx: { "& input": { textAlign: "right" } },
            }}
          />

          <FormControlLabel
            sx={{ gridColumn: "span 4" }}
            control={
              <Checkbox
                value="remember"
                color="secondary"
                onChange={handleChangeActive}
                checked={active}
              />
            }
            label="Provedora Ativa"
          />
        </Box>
      )}
      {provider && (
        <Box
          borderRadius="10px"
          marginTop="20px"
          padding="20px"
          backgroundColor={colors.grey[100]}
          display="grid"
          gap="15px"
          gridTemplateColumns="repeat(8, 1fr)"
        >
          <Box
            sx={{ gridColumn: isNonMobile ? "span 4" : "span 8" }}
            visibility={false}
          />
          <Button
            type="submit"
            disabled={!provider || !store}
            color="secondary"
            variant="contained"
            height="100%"
            onClick={handleSubmit}
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          >
            <Typography fontWeight="bold">Salvar</Typography>
          </Button>
          <ColorButton
            variant="contained"
            height="100%"
            onClick={handleClose}
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          >
            {" "}
            <Typography fontWeight="bold">Cancelar</Typography>
          </ColorButton>
        </Box>
      )}
      <DeleteStoreProviderDialog
        openDeleteStoreProviderDialog={openDeleteStoreProviderDialog}
        handleCloseDeleteStoreProviderDialog={
          handleCloseDeleteStoreProviderDialog
        }
        handleCloseEditStoreProviderDialog={handleClose}
        provider_info={provider_info}
        providers={providers}
        stores={stores}
        storeProviders={storeProviders}
        setAlertMessage={setAlertMessage}
        setSeverity={setSeverity}
        setOpenAlert={setOpenAlert}
      />
    </Box>
  );
};
export default EditStoreProvider;
