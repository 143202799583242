import * as XLSX from "xlsx";
import dayjs from "dayjs";
import countries from "../../utils/countryList/countries2.json";

const readExcelFile = (data, worksheetName) => {
  try {
    const workbook = XLSX.read(data, { type: "buffer" });
    return workbook.Sheets[worksheetName];
  } catch (err) {}
};

const handleFileAnalysisLP = (
  file,
  callbackError,
  callbackSuccess,
  callBackEnd
) => {
  let USDollar = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 });

  var reader = new FileReader();

  reader.onload = (e) => {
    var data = e.target.result;
    const worksheet = readExcelFile(data, "Transactions Ledger");

    if (!worksheet) {
      callbackError("error", "Planilha não encontrada no Arquivo");
      callbackSuccess([]);
      callBackEnd(false);
    } else {
      var range = XLSX.utils.decode_range(worksheet["!ref"]);
      range.s.r = 2;
      worksheet["!ref"] = XLSX.utils.encode_range(range);
      const result = XLSX.utils.sheet_to_json(worksheet, { raw: false });
      const load = [];
      for (const transaction of result) {
        if (!dayjs(transaction["Date & Time"]).isValid()) {
          callbackError("error", "Planilha com datas erradas");
          callbackSuccess([]);
        } else {
          const element = {
            dateTransaction: dayjs(transaction["Date & Time"]).format(
              "YYYY-MM-DD"
            ),
            destinyCountry:
              countries.filter(
                (country) => country.iso_code == transaction["Currency"]
              ).length === 1
                ? countries.filter(
                    (country) => country.iso_code === transaction["Currency"]
                  )[0].country
                : `Zona do ${transaction.Currency}`,
            sender: transaction["Sender Name"],
            senderPhone: transaction["Sender Phone"],
            recipient: transaction["Receiver Name"],
            amountSent: USDollar.format(
              Number(transaction["USD Sent"].replace(",", "").substring(1))
            ),
            fee: USDollar.format(Number(transaction.Fee.substring(1))),
            amountCollected: USDollar.format(
              Number(transaction.Fee.substring(1)) +
                Number(transaction["USD Sent"].replace(",", "").substring(1))
            ),
            paymentMethod: transaction["Payment Method"],
          };

          if (Number(element.amountSent.replace(",", "")) > 0) {
            load.push(element);
          }
        }
      }

      if (load.length === 0) {
        callbackError("error", "Não foram encontradas transações no arquivo.");
      } else {
        callbackSuccess(load);
      }
      callBackEnd(false);
    }
  };
  reader.readAsArrayBuffer(file);
};

const handleFileAnalysisUNO = (
  file,
  callbackError,
  callbackSuccess,
  callBackEnd
) => {
  let USDollar = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 });

  var reader = new FileReader();

  reader.onload = (e) => {
    var data = e.target.result;
    var allTextLines = data.split("\n");
    if (allTextLines.length < 2) {
      callbackError("error", "Informações não encontradas no arquivo");
      callbackSuccess([]);
    } else {
      var headers = allTextLines[0].split(",");
      var lines = [];

      if (
        !["P_REPCODE", "P_REPID"].includes(headers[0].replace(/['"]+/g, ""))
      ) {
        callbackError("error", "Informações não encontradas no arquivo");
        callbackSuccess([]);
        callBackEnd(false);
      } else {
        for (var i = 1; i < allTextLines.length; i++) {
          var data = allTextLines[i].split(",");
          if (data.length == headers.length) {
            var tarr = {};
            for (var j = 0; j < headers.length; j++) {
              tarr[headers[j].replace(/['"]+/g, "")] = data[j].replace(
                /['"]+/g,
                ""
              );
            }
            lines.push(tarr);
          }
        }
        const load = [];

        if (headers[0].replace(/['"]+/g, "") === "P_REPID") {
          for (const transaction of lines) {
            if (
              dayjs(
                transaction["RUNBALDATE"].split(" ")[0],
                "YYYY-MM-DD",
                true
              ).isValid() &&
              transaction.TYPE === "New Transaction"
            ) {
              const element = {
                dateTransaction: dayjs(
                  `${transaction["RUNBALDATE"].split(" ")[0]}`,
                  "YYYY-MM-DD"
                ).format("YYYY-MM-DD"),
                destinyCountry: "NÃO INFORMADO",
                sender: transaction["SENDERFULLNAME"],
                senderPhone: "NÃO INFORMADO",
                recipient: "NÃO INFORMADO",
                amountSent: USDollar.format(
                  Number(transaction["PRINCIPAL_G1_T"].replace(",", ""))
                ),
                fee: USDollar.format(
                  Number(transaction["FIXEDFEE_G1_T"].replace(",", ""))
                ),
                amountCollected: USDollar.format(
                  Number(transaction["TOTALSALE_G1_T"].replace(",", ""))
                ),
                //profit: "O",
                paymentMethod: transaction["SENDINGPAYMENTTYPE"],
              };
              if (Number(element.amountSent.replace(",", "")) > 0)
                load.push(element);
            }
          }
        } else if (headers[0].replace(/['"]+/g, "") === "P_REPCODE") {
          for (const transaction of lines) {
            if (
              dayjs(
                transaction["BALANCEDATE"].split(" ")[0],
                "YYYY-MM-DD",
                true
              ).isValid() &&
              transaction.TYPE === "New Transaction" &&
              transaction["STATUS"] === "Pago"
            ) {
              const element = {
                dateTransaction: dayjs(
                  transaction["BALANCEDATE"].split(" ")[0],
                  "YYYY-MM-DD"
                ).format("YYYY-MM-DD"),
                destinyCountry: transaction["PAYOUTCOUNTRY"],
                sender: transaction["SENDERFULLNAME"],
                senderPhone: "NÃO INFORMADO",
                recipient: transaction["BENEFICIARYFULLNAME"],
                amountSent: USDollar.format(
                  Number(transaction["PRINCIPAL_G1_T"].replace(",", ""))
                ),
                fee: USDollar.format(
                  Number(transaction["TOTALFEE_G1_T"].replace(",", ""))
                ),
                amountCollected: USDollar.format(
                  Number(transaction["TOTALSALE_G1_T"].replace(",", ""))
                ),
                //profit: "O",
                paymentMethod: "NÃO INFORMADO",
              };
              if (Number(element.amountSent.replace(",", "")) > 0)
                load.push(element);
            }
          }
        }

        if (load.length === 0) {
          callbackError(
            "error",
            "Não foram encontradas transações no arquivo."
          );
        } else {
          callbackSuccess(load);
        }
        callBackEnd(false);
      }
    }
  };
  reader.readAsText(file);
};

const handleFileAnalysisWU = (
  file,
  callbackError,
  callbackSuccess,
  callBackEnd
) => {
  let USDollar = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 });
  //const countries = countriesJSON;

  var reader = new FileReader();

  reader.onload = (e) => {
    var data = e.target.result;
    var allTextLines = data.split("\n");
    if (allTextLines.length < 4) {
      callbackError("error", "Informações não encontradas no arquivo");
      callbackSuccess([]);
      callBackEnd(false);
    } else {
      var headers = allTextLines[3].split(",");
      var lines = [];

      if (headers.length !== 26) {
        callbackError("error", "Informações não encontradas no arquivo");
        callbackSuccess([]);
      } else {
        for (var i = 4; i < allTextLines.length; i++) {
          var data = allTextLines[i].split(",");
          if (data.length == headers.length) {
            var tarr = {};
            for (var j = 0; j < headers.length; j++) {
              tarr[headers[j].replace(/['"]+/g, "")] = data[j].replace(
                /['"]+/g,
                ""
              );
            }
            lines.push(tarr);
          }
        }
        const load = [];
        for (const transaction of lines) {
          if (
            dayjs(transaction["Date"], "DD/MM/YYYY", true).isValid() &&
            transaction["Destination Country Code"] !== "US"
          ) {
            const element = {
              dateTransaction: dayjs(
                `${transaction["Date"]}-${transaction["Time"]}`,
                "DD/MM/YYYY-h:mm A"
              ).format("YYYY-MM-DD HH:mm"),
              destinyCountry:
                countries.filter(
                  (country) =>
                    country.iso_code === transaction["Destination Country Code"]
                )[0]?.country || "NÃO INFORMADO",
              sender: transaction["Sender"],
              senderPhone: "NÃO INFORMADO",
              recipient: transaction["Receiver Name"],
              amountSent: USDollar.format(
                Number(transaction["Amount Sent"].replace(",", ""))
              ),
              fee: USDollar.format(
                Number(transaction["Charge"].replace(",", ""))
              ),
              amountCollected: USDollar.format(
                Number(transaction["Amount Sent"].replace(",", "")) +
                  Number(transaction["Charge"].replace(",", ""))
              ),
              //profit: "O",
              paymentMethod: transaction["Payment Type"],
            };
            if (Number(element.amountSent.replace(",", "")) > 0)
              load.push(element);
          }
        }
        if (load.length === 0) {
          callbackError(
            "error",
            "Não foram encontradas transações no arquivo."
          );
        } else {
          callbackSuccess(load);
        }
        callBackEnd(false);
      }
    }
  };
  reader.readAsText(file);
};

const handleFileAnalysisRIA = (
  file,
  callbackError,
  callbackSuccess,
  callBackEnd
) => {
  let USDollar = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 });

  var reader = new FileReader();

  reader.onload = (e) => {
    var data = e.target.result;
    var allTextLines = data
      .split("\n")
      .filter((line) => line.slice(1, 3) === "US");
    var headers = [
      "Order Number",
      "Order Date",
      "Customer",
      "Seq. ID",
      "Currency From",
      "Amount Sent",
      "Fee",
      "Tax",
      "Total",
      "Comm",
    ];

    var lines = [];

    if (allTextLines.length < 1) {
      callbackError("error", "Dados não encontrados no arquivo");
      callbackSuccess([]);
      callBackEnd(false);
    } else if (
      allTextLines[0].split(/,(?=(?:[^"]*"[^"]*")*[^"]*$)/g).length !==
      headers.length
    ) {
      callbackError("error", "Dados não encontrados no arquivo");
      callbackSuccess([]);
      callBackEnd(false);
    } else {
      for (var i = 0; i < allTextLines.length; i++) {
        var data = allTextLines[i].match(/(".*?"|[^",\s]+)(?=\s*,|\s*$)/g);
        if (data.length == headers.length) {
          var tarr = {};
          for (var j = 0; j < headers.length; j++) {
            tarr[headers[j].replace(/['"]+/g, "")] = data[j].replace(
              /['"]+/g,
              ""
            );
          }
          lines.push(tarr);
        }
      }
      const load = [];

      for (const transaction of lines) {
        if (
          dayjs(transaction["Order Date"], "M/D/YYYY h:mm:ss A", true).isValid()
        ) {
          const element = {
            dateTransaction: dayjs(transaction["Order Date"]).format(
              "YYYY-MM-DD HH:mm"
            ),
            destinyCountry: "NÃO INFORMADO",
            sender: transaction["Customer"],
            senderPhone: "NÃO INFORMADO",
            recipient: "NÃO INFORMADO",
            amountSent: USDollar.format(
              Number(transaction["Amount Sent"].replace(",", ""))
            ),
            fee: USDollar.format(Number(transaction["Fee"].replace(",", ""))),
            amountCollected: USDollar.format(
              Number(transaction["Amount Sent"].replace(",", "")) +
                Number(transaction["Fee"].replace(",", ""))
            ),
            //profit: 0,
            paymentMethod: "NÃO INFORMADO",
          };
          if (Number(element.amountSent.replace(",", "")) > 0)
            load.push(element);
        }
      }
      if (load.length === 0) {
        callbackError("error", "Não foram encontradas transações no arquivo.");
      } else {
        callbackSuccess(load);
      }
      callBackEnd(false);
    }
  };
  reader.readAsText(file);
};

const handleFileAnalysisGolden = (
  file,
  callbackError,
  callbackSuccess,
  callBackEnd
) => {
  let USDollar = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 });
  //const countries = countriesJSON;
  var reader = new FileReader();

  reader.onload = (e) => {
    var data = e.target.result;
    var allTextLines = data.split("\n");
    var headers1 = [
      "Receipt",
      "Passwor",
      "Creation_date",
      "Statuss",
      "Payer",
      "Agency_Name",
      "Cashier",
      "Sender",
      "Receiver",
      "Amount_sent",
      "Fees",
      "Total",
      "Amount_receive",
      "Currency",
      "OriginPaymentMethod",
      "Textbox70",
      "Textbox71",
      "Textbox72",
      "Textbox73",
    ];

    var headers2 = [
      "GIR_RECIBO2",
      "ThirdParty",
      "GIR_FECHA2",
      "gir_STATUS2",
      "PMO_NAME",
      "GIR_CORRESPONSAL_NOMBRE2",
      "Textbox83",
      "GIR_Cajero2",
      "GIR_Cantidad2",
      "GIR_TOTAL2",
      "Textbox84",
      "Textbox85",
      "GIR_TipoCambio_Real2",
      "GIR_TipoCambio2",
      "GIR_COMISIONAGENCIA2",
      "GIR_COMISIONAGENCIA_FX2",
      "GIR_COMISIONMTS2",
      "Textbox86",
      "GIR_OTROS2",
      "GIR_COMISION2",
      "Textbox87",
      "Textbox88",
      "Textbox92",
      "Textbox93",
      "Textbox95",
      "Textbox98",
      "Textbox99",
      "Textbox100",
      "Textbox39",
      "Textbox102",
      "Textbox103",
      "Textbox104",
      "Textbox105",
      "txtAmntSent4",
      "wires",
      "txtFeesFX",
      "txtOthers",
      "cancelled",
      "txtAmntSent",
      "txtAmntSent3",
      "total",
      "txtFees",
      "txtAmntSent2",
      "Textbox935",
      "Textbox938",
      "Textbox259",
      "Textbox1147",
      "Textbox1148",
      "Textbox1149",
    ];
    var lines = [];

    if (allTextLines.length < 1) {
      callbackError("error", "Informações não encontradas no arquivo");
      callbackSuccess([]);
      callBackEnd(false);
    } else if (
      ![headers1[0], headers2[0]].includes(allTextLines[0].split(",")[0])
    ) {
      callbackError("error,", "Dados não encontrados no arquivo");
      callbackSuccess([]);
      callBackEnd(false);
    } else {
      if (headers1[0] === allTextLines[0].split(",")[0]) {
        for (var i = 1; i < allTextLines.length; i++) {
          var data = allTextLines[i].split(/,(?=(?:[^"]*"[^"]*")*[^"]*$)/g);
          if (data.length == headers1.length) {
            var tarr = {};
            for (var j = 0; j < headers1.length; j++) {
              tarr[headers1[j]] = data[j].replace(/['"]+/g, "");
            }
            lines.push(tarr);
          }
        }
        const load = [];

        for (const transaction of lines) {
          if (
            dayjs(
              transaction["Creation_date"],
              "M/D/YYYY h:mm:ss A",
              true
            ).isValid() &&
            transaction["Statuss"] === "PAID"
          ) {
            const element = {
              dateTransaction: dayjs(transaction["Creation_date"]).format(
                "YYYY-MM-DD HH:mm"
              ),
              destinyCountry:
                countries.filter(
                  (country) => country.iso_code == transaction["Currency"]
                ).length === 1
                  ? countries.filter(
                      (country) => country.iso_code === transaction["Currency"]
                    )[0].country
                  : `Zona do ${transaction.Currency}`,
              sender: transaction["Sender"],
              senderPhone: "NÃO INFORMADO",
              recipient: transaction["Receiver"],
              amountSent: USDollar.format(
                Number(transaction["Amount_sent"].replace("$", ""))
              ),
              fee: USDollar.format(
                Number(transaction["Fees"].replace("$", ""))
              ),
              //profit: 0,
              amountCollected: USDollar.format(
                Number(transaction["Amount_sent"].replace("$", "")) +
                  Number(transaction["Fees"].replace("$", ""))
              ),
              paymentMethod: transaction["OriginPaymentMethod"],
            };
            if (Number(element.amountSent.replace(",", "")) > 0)
              load.push(element);
          }
        }
        if (load.length === 0) {
          callbackError(
            "error",
            "Não foram encontradas transações no arquivo."
          );
        } else {
          callbackSuccess(load);
        }
        callBackEnd(false);
      } else if (headers2[0] === allTextLines[0].split(",")[0]) {
        for (var i = 1; i < allTextLines.length; i++) {
          var data = allTextLines[i].split(/,(?=(?:[^"]*"[^"]*")*[^"]*$)/g);
          if (data.length == headers2.length) {
            var tarr = {};
            for (var j = 0; j < headers2.length; j++) {
              tarr[headers2[j]] = data[j].replace(/['"]+/g, "");
            }
            lines.push(tarr);
          }
        }
        const load = [];

        for (const transaction of lines) {
          if (
            dayjs(
              transaction["GIR_FECHA2"],
              "M/D/YYYY h:mm:ss A",
              true
            ).isValid()
          ) {
            var senderName =
              transaction["Textbox83"].split(", ")[1] +
              " " +
              transaction["Textbox83"].split(", ")[0];
            const element = {
              dateTransaction: dayjs(
                transaction["GIR_FECHA2"],
                "M/D/YYYY h:mm:ss A"
              ).format("YYYY-MM-DD HH:mm"),
              destinyCountry: "NÃO INFORMADO",
              sender: senderName || "",
              senderPhone: "NÃO INFORMADO",
              recipient: "NÃO INFORMADO",
              amountSent: USDollar.format(
                Number(transaction["GIR_Cantidad2"].replace(",", ""))
              ),
              fee: USDollar.format(
                Number(transaction["GIR_TOTAL2"].replace(",", "")) -
                  Number(transaction["GIR_Cantidad2"].replace(",", ""))
              ),
              //profit: 0,
              amountCollected: USDollar.format(
                Number(transaction["GIR_TOTAL2"].replace(",", ""))
              ),
              paymentMethod: transaction["PMO_NAME"],
            };
            if (Number(element.amountSent.replace(",", "")) > 0 && senderName)
              load.push(element);
          }
        }
        if (load.length === 0) {
          callbackError(
            "error",
            "Não foram encontradas transações no arquivo."
          );
        } else {
          callbackSuccess(load);
        }
        callBackEnd(false);
      }
    }
  };
  reader.readAsText(file);
};

export {
  handleFileAnalysisLP,
  handleFileAnalysisUNO,
  handleFileAnalysisWU,
  handleFileAnalysisRIA,
  handleFileAnalysisGolden,
};
