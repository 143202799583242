import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  Modal,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const MsBManagerWelcome = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  // Function to handle link navigation
  const handleLinkClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <Box sx={styles.container}>
      {/* Dicas Section */}
      <Box sx={styles.dicas}>
        <img
          src="http://msbmanager.us/pt/wp-content/uploads/sites/2/2024/12/lamp-light-svgrepo-com.svg"
          alt="Ícone Dicas"
          style={styles.icon}
        />
        <Typography variant="h6" sx={styles.dicasText}>
          Dicas
        </Typography>
      </Box>

      {/* Header Section */}
      <Box sx={styles.header}>
        <Typography variant="h5" sx={styles.title}>
          Bem-vindo à MsB Manager!
        </Typography>
        <Typography variant="body2" sx={styles.subtitle}>
          Estamos muito felizes por você estar aqui. Vamos te ajudar a
          aproveitar ao máximo do nosso sistema!
        </Typography>
      </Box>

      {/* Sections */}
      <Grid container spacing={2} sx={styles.sections}>
        {/* Section 1: Video */}
        <Grid item xs={12} md={4}>
          <Card sx={styles.section} onClick={handleOpenModal}>
            <CardActionArea sx={styles.cardActionArea}>
              <CardContent sx={styles.sectionContent}>
                <img
                  src="http://msbmanager.us/pt/wp-content/uploads/sites/2/2024/12/Dicas-3.png"
                  alt="Imagem Vídeo"
                  style={styles.sectionImage}
                />
                <Box sx={styles.sectionText}>
                  <Typography variant="subtitle1" sx={styles.sectionTitle}>
                    <img
                      src="http://msbmanager.us/pt/wp-content/uploads/sites/2/2024/12/video-svgrepo-com.svg"
                      alt="Ícone Vídeo"
                      style={styles.icon}
                    />
                    Confira o sistema em ação
                  </Typography>
                  <Typography variant="body2" sx={styles.sectionDescription}>
                    Assista ao vídeo de demonstração e descubra como os recursos
                    podem facilitar seu trabalho.
                  </Typography>
                </Box>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        {/* Section 2: Primeiros Passos */}
        <Grid item xs={12} md={4}>
          <Card
            sx={styles.section}
            onClick={() =>
              handleLinkClick("https://msbmanager.us/pt/primeiros-passos/")
            }
          >
            <CardActionArea sx={styles.cardActionArea}>
              <CardContent sx={styles.sectionContent}>
                <img
                  src="http://msbmanager.us/pt/wp-content/uploads/sites/2/2024/12/Dicas-4.png"
                  alt="Imagem Passos"
                  style={styles.sectionImage}
                />
                <Box sx={styles.sectionText}>
                  <Typography variant="subtitle1" sx={styles.sectionTitle}>
                    <img
                      src="http://msbmanager.us/pt/wp-content/uploads/sites/2/2024/12/businessman-ascending-by-stair-steps-svgrepo-com.svg"
                      alt="Ícone Passos"
                      style={styles.icon}
                    />
                    Primeiros Passos
                  </Typography>
                  <Typography variant="body2" sx={styles.sectionDescription}>
                    Siga nosso passo a passo para começar a usar o sistema agora
                    mesmo e otimizar a gestão das suas remessas.
                  </Typography>
                </Box>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        {/* Section 3: Central de Ajuda */}
        <Grid item xs={12} md={4}>
          <Card
            sx={styles.section}
            onClick={() =>
              handleLinkClick("https://msbmanagerkb.tawk.help/pt-br")
            }
          >
            <CardActionArea sx={styles.cardActionArea}>
              <CardContent sx={styles.sectionContent}>
                <img
                  src="http://msbmanager.us/pt/wp-content/uploads/sites/2/2024/12/Dicas-2.png"
                  alt="Imagem Central de Ajuda"
                  style={styles.sectionImage}
                />
                <Box sx={styles.sectionText}>
                  <Typography variant="subtitle1" sx={styles.sectionTitle}>
                    <img
                      src="http://msbmanager.us/pt/wp-content/uploads/sites/2/2024/12/help-message-question-mark-question-mark-svgrepo-com.svg"
                      alt="Ícone Ajuda"
                      style={styles.icon}
                    />
                    Precisa de Ajuda?
                  </Typography>
                  <Typography variant="body2" sx={styles.sectionDescription}>
                    Acesse nossa central de suporte para tirar dúvidas ou fale
                    com a gente!
                  </Typography>
                </Box>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>

      {/* Modal */}
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box sx={styles.modalContent}>
          <IconButton
            sx={styles.closeButton}
            onClick={handleCloseModal}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <iframe
            id="videoIframe"
            src="https://www.youtube.com/embed/37Tdrdgzxdw?si=yUAqpgdDkZ4E61od"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={styles.iframe}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default MsBManagerWelcome;

// Styles
const styles = {
  container: {
    maxWidth: 1200,
    margin: "10px auto",
    padding: "10px",
    border: "2px solid #d9e6f2",
    borderRadius: "8px",
    backgroundColor: "#fbfeff",
  },
  dicas: {
    display: "flex",
    alignItems: "center",
    marginBottom: "5px",
  },
  dicasText: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#0964fd",
  },
  icon: {
    width: "20px",
    height: "20px",
    marginRight: "6px",
  },
  header: {
    textAlign: "left",
    marginBottom: "5px",
  },
  title: {
    fontSize: "20px",
    color: "#0a1d3d",
    marginBottom: "3px",
  },
  subtitle: {
    fontSize: "14px",
    color: "#0056b3",
    marginTop: 0,
  },
  sections: {
    marginTop: "0px",
  },
  section: {
    backgroundColor: "#fbfeff",
    borderRadius: "8px",
    transition: "transform 0.2s",
    height: "100%", // Ensure the card takes full height
    display: "flex",
    flexDirection: "column",
    "&:hover": {
      transform: "scale(1.02)",
    },
  },
  cardActionArea: {
    flex: 1, // Ensure the CardActionArea takes full height
    display: "flex",
    flexDirection: "column",
  },
  sectionContent: {
    display: "flex",
    alignItems: "flex-start",
    gap: "10px",
    padding: "8px",
    flex: 1, // Ensure the content takes full height
  },
  sectionImage: {
    width: "auto",
    height: "40px",
    maxWidth: "60px",
    borderRadius: "8px",
    marginTop: "5px",
  },
  sectionText: {
    flex: 1,
  },
  sectionTitle: {
    fontSize: "14px",
    color: "#0a1d3d",
    marginBottom: "5px",
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  sectionDescription: {
    fontSize: "12px",
    color: "#0056b3",
  },
  modalContent: {
    position: "relative",
    margin: "10% auto",
    padding: "15px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    width: "80%",
    maxWidth: "600px",
  },
  closeButton: {
    position: "absolute",
    top: "5px",
    right: "10px",
  },
  iframe: {
    width: "100%",
    height: "300px",
    border: "none",
  },
};
