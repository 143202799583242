import {
  Box,
  Button,
  TextField,
  useTheme,
  Autocomplete,
  Typography,
  Paper,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useState, useEffect } from "react";
import { tokens } from "../../theme";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputAdornment from "@mui/material/InputAdornment";
import dayjs from "dayjs";
import { NumericFormat } from "react-number-format";
import useAxiosPrivate from "../../api/useAxiosPrivate";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import SnippetFolderOutlinedIcon from "@mui/icons-material/SnippetFolderOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { useTranslation } from "react-i18next";
import countriesJSON from "../../utils/countryList/countries.json";
import { red } from "@mui/material/colors";
import DeleteTransactionDialog from "../../components/DeleteTransactionDialog";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
const today = dayjs();

const EditTransaction = (props) => {
  const formData = new FormData();
  const {
    handleClose,
    stores,
    storeProviders,
    transactionInfo,
    setAlertMessage,
    setOpenAlert,
    setSeverity,
  } = props;

  //Configurações
  const EDIT_TRANSACTION_URL = `/transactions/${transactionInfo.id}`;
  const axiosPrivate = useAxiosPrivate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { t } = useTranslation();
  let USDollar = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 });

  //Informações selecionáveis
  const [editMode, setEditMode] = useState(false);

  const [clients, setClients] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const countries = countriesJSON;
  const [clientRecipients, setClientRecipients] = useState([]);
  const paymentMethods = [
    { id: 1, name: "Cash" },
    { id: 2, name: "Link Remoto" },
    { id: 3, name: "Cartão de Débito" },
    { id: 4, name: "Outro" },
  ];

  //Informações da Transação
  const [store, setStore] = useState(
    stores.filter((store) => store.id === transactionInfo.storeId)[0] || null
  );
  const [client, setClient] = useState(
    clients.filter((client) => client.id === transactionInfo.clientId)[0] ||
      null
  );
  const [recipient, setRecipient] = useState(
    recipients.filter(
      (recipient) => recipient.id === transactionInfo.recipientId
    )[0] || null
  );
  const [amountSent, setAmountSent] = useState(transactionInfo.amountSent || 0);
  const [floatAmountSent, setFloatAmountSent] = useState(
    transactionInfo.amountSent || 0
  );

  const [amountCollected, setAmountCollected] = useState(
    transactionInfo.amountCollected || 0
  );

  const [fee, setFee] = useState(USDollar.format(transactionInfo.fee || 0));
  const [floatFee, setFloatFee] = useState(transactionInfo.fee || 0);

  const [paymentMethod, setPaymentMethod] = useState(
    paymentMethods.filter(
      (paymentMethod) => paymentMethod.name === transactionInfo.paymentMethod
    )[0] || { id: 4, name: "Outro" }
  );
  const [provider, setProvider] = useState(
    transactionInfo.storeProvider || null
  );
  const [dateTransaction, setDateTransaction] = useState(
    dayjs(transactionInfo.dateTransaction)
  );
  const [destinyCountry, setDestinyCountry] = useState(
    countries.filter(
      (country) => country.country === transactionInfo.destinyCountry
    )[0] || {
      country: "Unknown",
      currency: "",
      iso_code: "",
      country_code: "",
    }
  );
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [filesAttachedManual, setFilesAttachedManual] = useState(
    transactionInfo.files.filter((file) => file.fileOrigin === "Transactions")
  );
  const [filesAttachedAuto, setFilesAttachedAuto] = useState(
    transactionInfo.files.filter((file) =>
      ["Document", "Proof of Funds", "Ledger"].includes(file.fileOrigin)
    )
  );

  const [fileType, setFileType] = useState("");
  const [fileName, setFileName] = useState("");
  const [filesToDelete, setFilesToDelete] = useState([]);

  const [openDeleteTransactionDialog, setOpenDeleteTransactionDialog] =
    useState(false);

  const handleOpenDeleteTransactionDialog = () => {
    setOpenDeleteTransactionDialog(true);
  };

  const handleCloseDeleteTransactionDialog = () => {
    setOpenDeleteTransactionDialog(false);
  };

  useEffect(() => {
    const getClients = async () => {
      try {
        const response = await axiosPrivate.get("/clients");
        setClients(response.data);
        setClient(
          response.data.filter(
            (client) => client.id === transactionInfo.clientId
          )[0]
        );
      } catch (err) {}
    };
    getClients();
  }, []);

  useEffect(() => {
    const getRecipients = async () => {
      try {
        const response = await axiosPrivate.get("/recipients");
        setRecipient(
          response.data.filter(
            (recipient) => recipient.id === transactionInfo.recipientId
          )[0] || ""
        );
      } catch (err) {}
    };
    getRecipients();
  }, []);

  const getRecipients = async (clientId) => {
    try {
      const response = await axiosPrivate.get(
        `/recipients/clients/${clientId}`
      );
      setClientRecipients(response.data);
    } catch (err) {}
  };

  const handleFileChange = (event) => {
    const files = event.target.files;

    Array.from(files).forEach((file) => {
      if (file.size / 1024 / 1024 >= 4) {
        setAlertMessage("O Arquivo deve ter no máximo 4MB");
        setSeverity("warning");
        setOpenAlert(true);
        setSelectedFiles([]);
      } else {
        setSelectedFiles(files);
        setFileName(files[0].name);
      }
    });
  };

  const handleUploadFile = async (e) => {
    e.preventDefault();
    setLoadingFiles(true);
    const fileFormData = new FormData();
    const fileOrigin = "Transactions";

    fileFormData.append("files", selectedFiles[0]);
    fileFormData.append("fileOrigin", fileOrigin);
    fileFormData.append("documentType", fileType);
    fileFormData.append("name", fileName);

    try {
      const response = await axiosPrivate.putForm(
        `/transactions/file/${transactionInfo.id}`,
        fileFormData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          credentials: "include",
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setAlertMessage(`Arquivo adicionado: ${response.data.fileName}`);
        setSeverity("success");
        setOpenAlert(true);
        setFilesAttachedManual([...filesAttachedManual, response.data]);
        setLoadingFiles(false);
        setSelectedFiles([]);
        setFileName("");
        setFileType("");
        //handleClose();
      }
    } catch (err) {
      if (!err?.response) {
        setAlertMessage(t("clients.editClient.serverFailAlertMessage"));
        setSeverity("error");
        setOpenAlert(true);
      } else if (err.response?.status === 400) {
        setAlertMessage(
          err.response?.data.message ||
            t("clients.editClient.serverFailAlertMessage")
        );
        setSeverity("error");
        setOpenAlert(true);
      } else {
        setAlertMessage(t("clients.editClient.otherFailAlertMessage"));
        setSeverity("error");
        setOpenAlert(true);
      }
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !store ||
      !client ||
      !recipient ||
      !amountCollected ||
      !provider ||
      !dateTransaction
    ) {
      setAlertMessage("Verfifique os campos obrigatórios");
      setSeverity("warning");
      setOpenAlert(true);
      return;
    }

    setLoadingFiles(true);

    formData.append("storeId", store.id);
    formData.append("clientId", client.id);
    formData.append("destinyCountry", destinyCountry?.country || "Unknown");
    formData.append("recipientId", recipient.id);
    formData.append("storeProviderId", provider.id);
    formData.append("amountCollected", amountCollected);
    formData.append("amountSent", floatAmountSent);
    formData.append("paymentMethod", paymentMethod?.name || "");
    formData.append("fee", floatFee);
    formData.append("dateTransaction", dateTransaction);

    try {
      const response = await axiosPrivate.putForm(
        EDIT_TRANSACTION_URL,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          credentials: "include",
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        setAlertMessage(`Remessa Alterada!`);
        setSeverity("success");
        setOpenAlert(true);
        setEditMode(false);
        setLoadingFiles(false);
      }
    } catch (err) {
      if (!err?.response) {
        setAlertMessage(
          "O servidor não está respondendo. Por favor tente novamente mais tarde."
        );
        setSeverity("error");
        setOpenAlert(true);
      } else if (err.response?.status === 400) {
        setAlertMessage(err.response?.data.message || "Erro");
        setSeverity("error");
        setOpenAlert(true);
      } else {
        setAlertMessage("Falha no Registro da Remessa");
        setSeverity("error");
        setOpenAlert(true);
      }
    }
  };

  const handleClientSelect = (client) => {
    setClient(client);
    getRecipients(client.id);
    setRecipient(null);
  };

  const handleRecipientSelect = (recipient) => {
    setRecipient(recipient);
  };

  const handleDeleteFile = (file) => {
    filesToDelete.includes(file)
      ? setFilesToDelete(filesToDelete.filter((f) => f !== file))
      : setFilesToDelete([...filesToDelete, file]);
  };

  const handleEditCancel = () => {
    setAmountCollected(transactionInfo.amountCollected);
    setAmountSent(transactionInfo.amountSent);
    setFee(USDollar.format(transactionInfo.fee));
    setPaymentMethod(
      paymentMethods.filter(
        (paymentMethod) => paymentMethod.name === transactionInfo.paymentMethod
      )[0] || { id: 4, name: "Outro" }
    );
    setProvider(transactionInfo.storeProvider);
    setDateTransaction(dayjs(transactionInfo.dateTransaction));
    setDestinyCountry(
      countries.filter(
        (country) => country.country === transactionInfo.destinyCountry
      )[0] || {
        country: "Unknown",
        currency: "",
        iso_code: "",
        country_code: "",
      }
    );
    setSelectedFiles([]);
    setFilesAttachedManual(
      transactionInfo.files.filter((file) => file.fileOrigin === "Transactions")
    );
    setFilesAttachedAuto(
      transactionInfo.files.filter((file) => file.fileOrigin === "Clients")
    );
    setFilesToDelete([]);
    setEditMode(false);
  };

  const handleDeleteTransaction = async (e) => {
    e.preventDefault();

    // if button enabled with JS hack

    try {
      const response = await axiosPrivate.delete(EDIT_TRANSACTION_URL, {
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        withCredentials: true,
      });
      if (response.status === 204) {
        setAlertMessage(`Remessa removida com sucesso!`);
        setSeverity("success");
        setOpenAlert(true);
        handleClose();
      }
    } catch (err) {
      if (!err?.response) {
        setAlertMessage(
          "O servidor não está respondendo. Por favor tente novamente mais tarde."
        );
        setSeverity("error");
        setOpenAlert(true);
      } else if (err.response?.status === 400) {
        setAlertMessage(err.response?.data.message || "Erro");
        setSeverity("error");
        setOpenAlert(true);
      } else {
        setAlertMessage("Falha no Registro da Remessa");
        setSeverity("error");
        setOpenAlert(true);
      }
    }
  };

  return (
    <Box>
      <Header
        title={editMode ? "EDITAR REMESSA" : "DETALHES DA REMESSA"}
        subtitle="Visualize e edite informações da Remessa"
        button={false}
        color="#9f1853"
      />
      <Box
        borderRadius="4px"
        padding="8px"
        backgroundColor={colors.grey[100]}
        display="flex"
        justifyContent="flex-end"
        gap="8px"
        //gridTemplateColumns="repeat(4, 1fr)"
        sx={{
          "& > div": { gridColumns: "span 4" },
        }}
      >
        {!editMode ? (
          <>
            <Button
              sx={{
                backgroundColor: colors.primary[500],
                color: colors.grey[100],
                "&:hover": {
                  backgroundColor: colors.primary[400],
                  color: "white",
                },
                width: "200px",
              }}
              id="edit-transaction"
              onClick={() => setEditMode(true)}
            >
              <ModeEditOutlineOutlinedIcon sx={{ mr: "10px" }} />
              <Typography fontWeight="bold">Editar Remessa</Typography>
            </Button>
            <Button
              sx={{
                backgroundColor: colors.grey[900],
                color: colors.grey[100],
                "&:hover": {
                  backgroundColor: colors.grey[800],
                  color: "white",
                },
                width: "20px",
              }}
              id="edit-client"
              onClick={handleOpenDeleteTransactionDialog}
            >
              <DeleteForeverOutlinedIcon />
            </Button>
          </>
        ) : (
          <Button
            sx={{
              backgroundColor: red[700],
              color: colors.grey[100],
              "&:hover": {
                backgroundColor: red[600],
                color: "white",
              },
              width: "200px",
            }}
            id="edit-client"
            onClick={handleEditCancel}
          >
            <ModeEditOutlineOutlinedIcon sx={{ mr: "10px" }} />
            <Typography fontWeight="bold">Cancelar Edição</Typography>
          </Button>
        )}
      </Box>

      <Box
        borderRadius="4px"
        marginTop="8px"
        padding="8px"
        backgroundColor={colors.grey[100]}
        display="grid"
        gap="8px"
        gridTemplateColumns="repeat(4, 1fr)"
        sx={{
          "& > div": { gridColumns: "span 4" },
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ gridColumn: "span 4" }}
        >
          Loja
        </Typography>
        <Autocomplete
          readOnly={!editMode}
          fullWidth
          className="autocomplete"
          required
          value={store}
          disableClearable
          variant="outlined"
          id="store"
          options={stores}
          getOptionLabel={(option) => option.name}
          sx={{
            gridColumn: isNonMobile ? "span 2" : "span 4",
          }}
          onChange={(_, newValue) => {
            setStore(newValue);
            setProvider(null);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label="Loja"
              sx={{
                backgroundColor: editMode
                  ? colors.primary[100]
                  : colors.grey[100],
              }}
            />
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
      </Box>

      {/*INFORMAÇÕES DO CLIENTE*/}
      {store && (
        <Box
          borderRadius="4px"
          marginTop="8px"
          padding="8px"
          backgroundColor={colors.grey[100]}
          display="grid"
          gap="4px"
          gridTemplateColumns="repeat(4, 1fr)"
          sx={{
            "& > div": { gridColumns: "span 4" },
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            gridColumn="span 4"
          >
            <Typography variant="h4" fontWeight="bold">
              Cliente
            </Typography>
          </Box>

          <Box sx={{ gridColumn: "span 4" }} />

          <Autocomplete
            readOnly={!editMode}
            fullWidth
            size="small"
            value={client}
            onChange={(event, newCliente) => {
              handleClientSelect(newCliente);
            }}
            disableClearable
            variant="outlined"
            id="nome-cliente"
            options={clients}
            getOptionLabel={(option) =>
              `${option.name} ${
                option.phoneNumber !== "NÃO INFORMADO" && option.phoneNumber
                  ? option.phoneNumber
                  : ""
              }`
            }
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Nome do Cliente"
                sx={{
                  backgroundColor: editMode
                    ? colors.primary[100]
                    : colors.grey[100],
                }}
              />
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          {client && (
            <>
              <TextField
                disabled
                size="small"
                fullWidth
                value={client.phoneNumber || ""}
                variant="outlined"
                type="text"
                label="Telefone"
                //name="Telefone"
                sx={{
                  gridColumn: isNonMobile ? "span 1" : "span 2",
                }}
              />
              <TextField
                disabled
                size="small"
                fullWidth
                value={client.city || ""}
                variant="outlined"
                type="text"
                label="Cidade"
                sx={{
                  gridColumn: isNonMobile ? "span 1" : "span 2",
                }}
              />
            </>
          )}
        </Box>
      )}
      {/* PARTE DO BENEFICIÁRO */}
      {client && (
        <Box
          borderRadius="10px"
          padding="8px"
          marginTop="20px"
          marginBottom="20px"
          backgroundColor={colors.grey[100]}
          display="grid"
          gridTemplateColumns="repeat(4, 1fr)"
          gap="4px"
          sx={{
            "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            gridColumn="span 4"
          >
            <Typography variant="h4" fontWeight="bold">
              Beneficiário
            </Typography>
          </Box>

          <Box sx={{ gridColumn: "span 4" }} visibility="hidden" />
          <Autocomplete
            readOnly={!editMode}
            value={recipient}
            onChange={(_, newRecipient) => {
              handleRecipientSelect(newRecipient);
            }}
            disableClearable
            variant="outlined"
            id="recipient-name"
            options={clientRecipients}
            getOptionLabel={(option) => `${option.name}`}
            sx={{ gridColumn: isNonMobile ? "span 3" : "span 2" }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label="Nome do Beneficiário"
                sx={{
                  backgroundColor: editMode
                    ? colors.primary[100]
                    : colors.grey[100],
                }}
              />
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          {recipient && (
            <TextField
              disabled
              size="small"
              fullWidth
              value={recipient.identificationDocument}
              variant="outlined"
              type="text"
              label="Documento"
              sx={{
                backgroundColor: colors.grey[100],
                gridColumn: isNonMobile ? "span 1" : "span 2",
              }}
              inputProps={{ readOnly: true }}
            />
          )}
        </Box>
      )}

      {/* PARTE DA REMESSA */}

      <Box
        borderRadius="4px"
        padding="8px"
        marginTop="8px"
        backgroundColor={colors.grey[100]}
        display="grid"
        gap="12px"
        sx={{
          "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
          gridTemplateColumns: "repeat(6, 1fr)",
        }}
      >
        <Box sx={{ gridColumn: "span 6" }}>
          <Typography variant="h4" fontWeight="bold">
            Remessa
          </Typography>
        </Box>

        <DatePicker
          readOnly={!editMode}
          disableFuture
          label="Data da Remessa (AAAA-MM-DD)"
          value={dateTransaction}
          defaultValue={today.date()}
          timezone="system"
          onChange={(newValue) => setDateTransaction(dayjs(newValue))}
          sx={{
            gridColumn: isNonMobile ? "span 2" : "span 3",
            backgroundColor: editMode ? colors.primary[100] : colors.grey[100],
          }}
          format="YYYY-MM-DD"
        />
        <Autocomplete
          readOnly={!editMode}
          fullWidth
          required
          value={destinyCountry}
          disableClearable
          variant="outlined"
          id="country"
          options={countries}
          getOptionLabel={(option) => option.country}
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 3" }}
          //key={(option) => option.country_code}
          onChange={(_, newValue) => setDestinyCountry(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="País de Destino"
              sx={{
                backgroundColor: editMode
                  ? colors.primary[100]
                  : colors.grey[100],
              }}
            />
          )}
          isOptionEqualToValue={(option, value) =>
            option.country_code === value.country_code
          }
        />
        <Autocomplete
          readOnly={!editMode}
          fullWidth
          required
          value={provider}
          disableClearable
          variant="outlined"
          id="provider"
          options={storeProviders
            .filter((provider) => provider.storeId === store.id)
            .filter((provider) => provider.active)}
          getOptionLabel={(option) => option.Provider.name}
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 3" }}
          onChange={(_, newValue) => setProvider(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Provedora"
              sx={{
                backgroundColor: editMode
                  ? colors.primary[100]
                  : colors.grey[100],
              }}
            />
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />

        <NumericFormat
          decimalScale={2}
          thousandSeparator
          value={amountSent}
          customInput={TextField}
          required
          id="amount"
          variant="outlined"
          type="text"
          label="Valor da Remessa sem Taxa"
          sx={{
            gridColumn: isNonMobile ? "span 2" : "span 3",
            backgroundColor: editMode ? colors.primary[100] : colors.grey[100],
          }}
          onValueChange={(e) => {
            setAmountSent(USDollar.format(e.value));
            setFloatAmountSent(e.floatValue);
            setAmountCollected(e.floatValue + floatFee);
          }}
          InputProps={{
            readOnly: !editMode,
            startAdornment: (
              <InputAdornment position="start">USD </InputAdornment>
            ),
            sx: { "& input": { textAlign: "right" } },
          }}
        />
        <NumericFormat
          decimalScale={2}
          fixedDecimalScale
          thousandSeparator
          value={fee}
          customInput={TextField}
          required
          id="fee"
          variant="outlined"
          type="text"
          label="Taxa"
          sx={{
            gridColumn: isNonMobile ? "span 2" : "span 3",
            backgroundColor: editMode ? colors.primary[100] : colors.grey[100],
          }}
          onValueChange={(e) => {
            setFee(e.value);
            setFloatFee(e.floatValue);
            setAmountCollected(floatAmountSent + e.floatValue);
          }}
          InputProps={{
            readOnly: !editMode,
            startAdornment: (
              <InputAdornment position="start">USD </InputAdornment>
            ),
            sx: { "& input": { textAlign: "right" } },
          }}
        />

        <NumericFormat
          readOnly={!editMode}
          decimalScale={2}
          fixedDecimalScale
          thousandSeparator
          value={amountCollected}
          customInput={TextField}
          id="valueSent"
          variant="outlined"
          type="text"
          label="Valor Coletado"
          sx={{
            gridColumn: isNonMobile ? "span 2" : "span 3",
            backgroundColor: colors.grey[200],
          }}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">USD </InputAdornment>
            ),
            sx: { "& input": { textAlign: "right" } },
          }}
        />
        <Box
          sx={{
            gridColumn: isNonMobile ? "span 4" : "span 3",
          }}
        />

        <Autocomplete
          readOnly={!editMode}
          fullWidth
          value={paymentMethod}
          //disableClearable
          variant="outlined"
          id="payment-method"
          options={paymentMethods}
          getOptionLabel={(option) => option.name}
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 3" }}
          onChange={(_, newValue) => setPaymentMethod(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Forma de Pagamento"
              sx={{
                backgroundColor: editMode
                  ? colors.primary[100]
                  : colors.grey[100],
              }}
            />
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
        {/*
          <NumericFormat
            disabled={amount <= 0 || !fee}
            decimalScale={2}
            fixedDecimalScale
            thousandSeparator
            value={quotationProvider}
            customInput={TextField}
            id="quotation-provider"
            variant="outlined"
            type="text"
            label="Cotação da Provedora"
            sx={{ gridColumn: isNonMobile ? "span 1" : "span 4" }}
            onValueChange={(e) => handleQuotationProvider(e.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {destinyCountry?.iso_code || ""}{" "}
                </InputAdornment>
              ),
              sx: { "& input": { textAlign: "right" } },
            }}
          />
          <Box
            display="grid"
            rowGap={isNonMobile ? "0px" : "15px"}
            columnGap="15px"
            gridTemplateColumns="repeat(3, 1)"
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          >
            <Box sx={{ gridColumn: isNonMobile ? "span 3" : "span 4" }} />
            <NumericFormat
              disabled={
                amount <= 0 || !quotationProvider || quotationProvider == 0
              }
              decimalScale={2}
              backgroundColor={"blue"}
              thousandSeparator
              value={quotationClient}
              customInput={TextField}
              id="quotation-client"
              variant="standard"
              type="text"
              label="Cotação do Cliente"
              sx={{
                gridColumn: isNonMobile ? "span 1" : "span 4",
                marginLeft: isNonMobile ? "20px" : "Opx",
              }}
              onValueChange={(e) => handleQuotationClient(e.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {destinyCountry?.iso_code || ""}{" "}
                  </InputAdornment>
                ),
                sx: { "& input": { textAlign: "right" } },
              }}
            />
            {isNonMobile && (
              <Typography
                align="center"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                sx={{ gridColumn: isNonMobile ? "span 1" : "span 1" }}
              >
                ou{" "}
              </Typography>
            )}
            <NumericFormat
              disabled={quotationClient > 0 || !amount || amount <= 0}
              decimalScale={2}
              thousandSeparator
              value={spread}
              customInput={TextField}
              required
              id="spread"
              variant="standard"
              type="text"
              label="Spread"
              sx={{
                gridColumn: isNonMobile ? "span 1" : "span 4",
                marginRight: isNonMobile ? "20px" : "0px",
              }}
              //onChange={(event) => setSpread(event.target.value)}
              onBlur={(event) => handleSpreadSelect(event.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {destinyCountry?.iso_code || ""}
                  </InputAdornment>
                ),
                sx: { "& input": { textAlign: "right" } },
              }}
            />
          </Box>
          <NumericFormat
            decimalScale={2}
            disabled={amount <= 0}
            thousandSeparator
            value={profit}
            customInput={TextField}
            required
            id="profit"
            variant="outlined"
            type="text"
            label="Lucro"
            sx={{ gridColumn: isNonMobile ? "span 1" : "span 4" }}
            onValueChange={(e) => handleProfitSelect(e.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">USD </InputAdornment>
              ),
              sx: { "& input": { textAlign: "right" } },
            }}
          />*/}
      </Box>
      {filesAttachedAuto.length > 0 ? (
        <Box
          borderRadius="4px"
          marginTop="12px"
          padding="8px"
          backgroundColor={colors.grey[100]}
          display="grid"
          gap="4px"
          gridTemplateColumns="repeat(20, 1fr)"
          sx={{
            "& > div": { gridColumns: "span 20" },
          }}
        >
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ gridColumn: "span 20" }}
          >
            Arquivos Adicionados Automaticamente
          </Typography>

          <TableContainer
            component={Paper}
            sx={{ gridColumn: "span 20", maxHeight: 200 }}
          >
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <b>Nome do Arquivo</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Tipo do Arquivo</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Opções</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filesAttachedAuto.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell>{row.fileName}</TableCell>
                    <TableCell>{row.documentType || ""}</TableCell>
                    <TableCell>
                      <IconButton href={row.url}>
                        <CloudDownloadOutlinedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <></>
      )}

      <Box
        borderRadius="4px"
        marginTop="12px"
        padding="8px"
        backgroundColor={colors.grey[100]}
        display="grid"
        gap="4px"
        gridTemplateColumns="repeat(20, 1fr)"
        sx={{
          "& > div": { gridColumns: "span 20" },
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ gridColumn: "span 20" }}
        >
          Arquivos
        </Typography>
        {filesAttachedManual.length > 0 ? (
          <TableContainer
            component={Paper}
            sx={{ gridColumn: "span 20", maxHeight: 200 }}
          >
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <b>Nome do Arquivo</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Tipo do Arquivo</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Opções</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filesAttachedManual.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell>{row.fileName}</TableCell>
                    <TableCell>{row.documentType || ""}</TableCell>
                    <TableCell>
                      <IconButton href={row.url}>
                        <CloudDownloadOutlinedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box sx={{ gridColumn: "span 20" }}>
            {" "}
            <Typography>Sem Documentos</Typography>
          </Box>
        )}
        <Box
          mt="8px"
          sx={{ gridColumn: "span 20" }}
          display="grid"
          gap="4px"
          gridTemplateColumns="repeat(20, 1fr)"
        >
          {
            //Botao de Upload
          }
          {editMode && (
            <Box
              mt="8px"
              sx={{ gridColumn: "span 20" }}
              display="grid"
              gap="4px"
              gridTemplateColumns="repeat(20, 1fr)"
            >
              <Button
                variant="contained"
                component="label"
                disabled={loadingFiles}
                sx={{
                  backgroundColor: colors.primary[500],
                  color: colors.grey[100],
                  "&:hover": {
                    backgroundColor: colors.primary[600],
                    color: "white",
                  },
                  gridColumn: "span 1",
                }}
              >
                <SnippetFolderOutlinedIcon />
                <input
                  type="file"
                  name="files"
                  hidden
                  onChange={handleFileChange}
                />
              </Button>
              <TextField
                id="newFile"
                size="small"
                label={"Arquivo"}
                disabled={loadingFiles || selectedFiles.length === 0}
                variant="outlined"
                value={fileName}
                onChange={(e) => setFileName(e.target.value)}
                sx={{
                  gridColumn: "span 9",
                  backgroundColor:
                    selectedFiles.length > 0
                      ? colors.primary[100]
                      : colors.grey[100],
                }}
              />
              <TextField
                id="newFileType"
                inputProps={{
                  readOnly: !editMode || selectedFiles.length === 0,
                }}
                size="small"
                label={t("Tipo do Arquivo")}
                variant="outlined"
                value={fileType}
                disabled={loadingFiles || selectedFiles.length === 0}
                onChange={(e) => setFileType(e.target.value)}
                sx={{
                  gridColumn: "span 8",
                  backgroundColor:
                    selectedFiles.length > 0
                      ? colors.primary[100]
                      : colors.grey[100],
                }}
              />

              <Button
                sx={{
                  backgroundColor: colors.primary[500],
                  color: colors.grey[100],
                  "&:hover": {
                    backgroundColor: colors.primary[600],
                    color: "white",
                  },
                  gridColumn: "span 2",
                }}
                disabled={!selectedFiles.length > 0 || loadingFiles}
                onClick={handleUploadFile}
              >
                <CloudUploadOutlinedIcon />
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <Box />
      <Box height="20px">
        <Box
          borderRadius="10px"
          padding="10px"
          marginTop="20px"
          marginBottom="20px"
          backgroundColor={colors.grey[100]}
          display="flex"
          justifyContent="flex-end"
          gap="5px"
          sx={{
            "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
            gridTemplateColumns: "repeat(4, 1fr)",
          }}
        >
          <Box sx={{ gridColumn: "span 4" }} />
          <Box sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }} />
          {editMode && (
            <Button
              type="submit"
              disabled={
                !store ||
                !client ||
                !recipient ||
                !amountCollected ||
                !amountSent ||
                !fee ||
                !provider ||
                !dateTransaction ||
                loadingFiles
              }
              sx={{
                backgroundColor: colors.primary[500],
                color: colors.grey[100],
                "&:hover": {
                  backgroundColor: colors.primary[600],
                  color: "white",
                },
                gridColumn: isNonMobile ? "span 1" : "span 2",
              }}
              variant="contained"
              height="100%"
              onClick={handleSubmit}
            >
              {loadingFiles ? (
                <Typography fontWeight="bold">
                  Carregando Arquivos...
                </Typography>
              ) : (
                <Typography fontWeight="bold">Salvar Edição</Typography>
              )}
            </Button>
          )}
          <Button
            sx={{
              backgroundColor: red[700],
              color: colors.grey[100],
              "&:hover": {
                backgroundColor: red[600],
                color: "white",
              },
              gridColumn: isNonMobile ? "span 1" : "span 2",
            }}
            variant="contained"
            height="100%"
            onClick={handleClose}
          >
            <Typography fontWeight="bold">Cancelar</Typography>
          </Button>
        </Box>
      </Box>
      <DeleteTransactionDialog
        openDeleteTransactionDialog={openDeleteTransactionDialog}
        handleClose={handleCloseDeleteTransactionDialog}
        handleCloseEditTransactionDialog={handleClose}
        setAlertMessage={setAlertMessage}
        setSeverity={setSeverity}
        setOpenAlert={setOpenAlert}
        transactionId={transactionInfo.id}
      />
    </Box>
  );
};

export default EditTransaction;
