import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { tokens } from "../../theme";
import { storeContext } from "../../context/storeContext";
import { useTranslation } from "react-i18next";

import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";

import Header from "../../components/Header";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import useAxiosPrivate from "../../api/useAxiosPrivate";
import SelectStore from "../../components/SelectStore";
import PieChart from "../../components/PieChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import CenterValuePieChart from "./CenterValuePieChart.jsx";
import dayjs from "dayjs";
import {
  barDataProcessing,
  pieDataProcessing,
  statsBoxContentProcessing,
} from "./dataProcessing.js";

import MsBManagerWelcome from "../../components/BannerStater.js";

const Dashboard = () => {
  const axiosPrivate = useAxiosPrivate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { t } = useTranslation();

  let USDollar = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  let thousands = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  const [isNewUser, setIsNewUser] = useState(false);
  const [storesList, setStoresList] = useContext(storeContext);
  const [stores, setStores] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);

  const [transactions, setTransactions] = useState([]);
  const [storeProviders, setStoreProviders] = useState([]);

  const [barData, setBarData] = useState([]);
  const [barDataWeek, setBarDataWeek] = useState([]);
  const [barDataMonth, setBarDataMonth] = useState([]);
  const [barDataSemester, setBarDataSemester] = useState([]);

  const [pieAmountData, setPieAmountData] = useState([]);
  const [pieAmountDataWeek, setPieAmountDataWeek] = useState([]);
  const [pieAmountDataMonth, setPieAmountDataMonth] = useState([]);
  const [pieAmountDataSemester, setPieAmountDataSemester] = useState([]);

  const [pieQuantityData, setPieQuantityData] = useState([]);
  const [pieQuantityDataWeek, setPieQuantityDataWeek] = useState([]);
  const [pieQuantityDataMonth, setPieQuantityDataMonth] = useState([]);
  const [pieQuantityDataSemester, setPieQuantityDataSemester] = useState([]);

  const [amountSent, setAmountSent] = useState(0);
  const [quantitySent, setQuantitySent] = useState(0);

  const [periodSelected, setPeriodSelected] = useState("");

  const [statsBoxContent, setStatsBoxContent] = useState({});

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axiosPrivate.get("/users/userAge");
        if (dayjs(response.data.createdAt) >= dayjs().subtract(1, "month")) {
          setIsNewUser(true);
        }
      } catch (err) {}
    };

    getUser();
  }, []);

  useEffect(() => {
    const getTransactions = async () => {
      try {
        const response = await axiosPrivate.get("/transactions/storeChain180");
        setTransactions(response.data);
        barDataProcessing(
          response.data,
          setBarDataWeek,
          setBarDataMonth,
          setBarDataSemester
        );
        pieDataProcessing(
          response.data,
          setPieAmountDataWeek,
          setPieAmountDataMonth,
          setPieAmountDataSemester,
          setPieQuantityDataWeek,
          setPieQuantityDataMonth,
          setPieQuantityDataSemester
        );
        setStatsBoxContent(statsBoxContentProcessing(response.data));
      } catch (err) {}
    };
    getTransactions();
  }, []);

  useEffect(() => {
    const getStores = async () => {
      try {
        const response = await axiosPrivate.get("/stores");
        setStores(response.data);
      } catch (err) {}
    };
    getStores();
  }, []);

  useEffect(() => {
    const setChartsData = () => {
      let result = transactions.map((data) => {
        return {
          ...data,
          dateTransaction: dayjs(data.dateTransaction).format("YYYY-MM-DD"),
        };
      });

      if (storesList.length > 0) {
        result = result.filter((transaction) => {
          return storesList.some((obj) => {
            return obj.id === transaction.storeId;
          });
        });
      }
      barDataProcessing(
        result,
        setBarDataWeek,
        setBarDataMonth,
        setBarDataSemester
      );
      pieDataProcessing(
        result,
        setPieAmountDataWeek,
        setPieAmountDataMonth,
        setPieAmountDataSemester,
        setPieQuantityDataWeek,
        setPieQuantityDataMonth,
        setPieQuantityDataSemester
      );
      setStatsBoxContent(statsBoxContentProcessing(result));
      setFilteredTransactions(result);
    };
    setChartsData();
  }, [transactions, storeProviders, storesList]);

  useEffect(() => {
    setBarData(barDataWeek);
    setPieAmountData(pieAmountDataWeek);
    setPieQuantityData(pieQuantityDataWeek);
    setAmountSent(statsBoxContent.amountSentWeek);
    setQuantitySent(statsBoxContent.quantitySentWeek);
    setPeriodSelected("7 Days");
  }, [barDataWeek, pieAmountDataWeek, pieQuantityDataWeek, statsBoxContent]);

  const selectWeek = () => {
    setBarData(barDataWeek);
    setPieAmountData(pieAmountDataWeek);
    setPieQuantityData(pieQuantityDataWeek);
    setAmountSent(statsBoxContent.amountSentWeek);
    setQuantitySent(statsBoxContent.quantitySentWeek);
    setPeriodSelected("7 Days");
  };
  const selectMonth = () => {
    setBarData(barDataMonth);
    setPieAmountData(pieAmountDataMonth);
    setPieQuantityData(pieQuantityDataMonth);
    setAmountSent(statsBoxContent.amountSentMonth);
    setQuantitySent(statsBoxContent.quantitySentMonth);
    setPeriodSelected("30 Days");
  };
  const selectSemester = () => {
    setBarData(barDataSemester);
    setPieAmountData(pieAmountDataSemester);
    setPieQuantityData(pieQuantityDataSemester);
    setAmountSent(statsBoxContent.amountSentSemester);
    setQuantitySent(statsBoxContent.quantitySentSemester);
    setPeriodSelected("6 Months");
  };

  const compareByDate = (a, b) => {
    return dayjs(a.dateTransaction).unix() - dayjs(b.dateTransaction).unix();
  };

  transactions.sort(compareByDate).reverse();

  return (
    <Box m="8px">
      <Header
        title={t("dashboard.header.title")}
        subtitle={t("dashboard.header.subtitle")}
      />
      {isNewUser && <MsBManagerWelcome />}
      {/* GRID AND CHARTS */}
      <Box display="grid" gridTemplateColumns="repeat(10, 1fr)" gap="4px">
        {/* ROW 1*/}
        <Box gridColumn={isNonMobile ? "span 5" : "span 10"}>
          <SelectStore stores={stores} />
        </Box>
        <Box
          display="flex"
          justifyContent="space-around"
          gridColumn={isNonMobile ? "span 5" : "span 10"}
        >
          <Stack direction="row" spacing={1}>
            <Chip
              label={t("dashboard.statsBox.week")}
              onClick={selectWeek}
              sx={{
                backgroundColor:
                  periodSelected === "7 Days"
                    ? colors.primary[500]
                    : colors.grey[200],
                color:
                  periodSelected === "7 Days"
                    ? colors.grey[100]
                    : colors.primary[900],
              }}
            />
            <Chip
              label={t("dashboard.statsBox.month")}
              onClick={selectMonth}
              sx={{
                backgroundColor:
                  periodSelected === "30 Days"
                    ? colors.primary[500]
                    : colors.grey[200],
                color:
                  periodSelected === "30 Days"
                    ? colors.grey[100]
                    : colors.primary[900],
              }}
            />
            <Chip
              label={t("dashboard.statsBox.semester")}
              onClick={selectSemester}
              sx={{
                backgroundColor:
                  periodSelected === "6 Months"
                    ? colors.primary[500]
                    : colors.grey[200],
                color:
                  periodSelected === "6 Months"
                    ? colors.grey[100]
                    : colors.primary[900],
              }}
            />
          </Stack>
        </Box>
        <Box
          gridColumn={isNonMobile ? "span 2" : "span 5"}
          backgroundColor={colors.grey[100]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ borderRadius: 2 }}
        >
          <StatBox
            title={statsBoxContent.quantitySentToday || 0}
            subtitle={t("dashboard.statsBox.subtitleStatsBox1")}
            icon={
              <CurrencyExchangeOutlinedIcon
                sx={{ color: colors.primary[900], fontsize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn={isNonMobile ? "span 2" : "span 5"}
          backgroundColor={colors.grey[100]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ borderRadius: 2 }}
        >
          <StatBox
            title={`USD ${USDollar.format(
              statsBoxContent.amountSentToday || 0
            )}`}
            subtitle={t("dashboard.statsBox.subtitleStatsBox2")}
            icon={
              <PaymentsOutlinedIcon
                sx={{ color: colors.primary[900], fontsize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn={isNonMobile ? "span 2" : "span 5"}
          backgroundColor={colors.grey[100]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ borderRadius: 2 }}
        >
          <StatBox
            title={thousands.format(quantitySent) || 0}
            subtitle={
              `${t("dashboard.statsBox.subtitleStatsBox3")} ` +
              (periodSelected === "7 Days"
                ? `${t("dashboard.statsBox.week")}`
                : periodSelected === "30 Days"
                ? `${t("dashboard.statsBox.month")}`
                : `${t("dashboard.statsBox.semester")}`)
            }
            icon={
              <CurrencyExchangeOutlinedIcon
                sx={{ color: colors.primary[900], fontsize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn={isNonMobile ? "span 2" : "span 5"}
          backgroundColor={colors.grey[100]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ borderRadius: 2 }}
        >
          <StatBox
            title={`USD ${USDollar.format(amountSent || 0)}`}
            subtitle={
              `${t("dashboard.statsBox.subtitleStatsBox4")} ` +
              (periodSelected === "7 Days"
                ? `${t("dashboard.statsBox.week")}`
                : periodSelected === "30 Days"
                ? `${t("dashboard.statsBox.month")}`
                : `${t("dashboard.statsBox.semester")}`)
            }
            icon={
              <PaymentsOutlinedIcon
                sx={{ color: colors.primary[900], fontsize: "26px" }}
              />
            }
          />
        </Box>

        {isNonMobile && (
          <Box
            gridColumn="span 2"
            gridRow="span 3"
            backgroundColor={colors.grey[100]}
            sx={{ borderRadius: 2 }}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderBottom={`1px solid ${colors.grey[500]}`}
              colors={colors.primary[900]}
              p="4px"
            >
              <Typography
                color={colors.primary[900]}
                variant="h5"
                fontWeight="600"
              >
                {t("dashboard.charts.lastTransactions")}
              </Typography>
            </Box>
            {filteredTransactions.slice(0, 7).map((transaction) => (
              <Box
                key={transaction.id}
                display="flex"
                justifyContent="space-between"
                borderBottom={`1px solid black`}
                flexDirection="column"
                p="4px 8px"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                >
                  <Typography
                    color={colors.primary[900]}
                    fontSize={14}
                    fontWeight="600"
                  >
                    {transaction.client.name ||
                      `${t("dashboard.charts.unknownPerson")}`}
                  </Typography>
                  <Typography
                    color={colors.grey[500]}
                    justifyContent="left"
                    display="flex"
                  >
                    {transaction.recipient.name ||
                      `${t("dashboard.charts.unknownPerson")}`}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography>
                    {dayjs(transaction.dateTransaction).format("MMM-DD")}
                  </Typography>
                  <Typography>{transaction.store.name}</Typography>
                </Box>
                <Box
                  backgroundColor={colors.primary[300]}
                  p="3px"
                  borderRadius="4px"
                  justifyContent="center"
                  display={"flex"}
                >
                  <Typography>{`USD ${USDollar.format(
                    transaction.amountCollected
                  )}`}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        )}

        {/* ROW 2 */}
        <Box
          gridColumn={isNonMobile ? "span 8" : "span 10"}
          gridRow="span 1"
          backgroundColor={colors.grey[100]}
          sx={{ borderRadius: 2 }}
          mt="4px"
        >
          <Box
            p="4px 12px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.primary[900]}
              ></Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.primary[700]}
              >
                {`${t("dashboard.charts.lastPeriodBarChart")} ` +
                  (periodSelected === "7 Days"
                    ? `${t("dashboard.charts.week")}`
                    : periodSelected === "30 Days"
                    ? `${t("dashboard.charts.month")}`
                    : `${t("dashboard.charts.semester")}`)}
              </Typography>
            </Box>
          </Box>
          <Box height="250px" mt="-36px" padding="0 4px 0 4px">
            <BarChart
              data={barData}
              isNonMobile={isNonMobile}
              perProvider={false}
            />
          </Box>
        </Box>

        {/* ROW 3*/}
        <Box
          gridColumn={isNonMobile ? "span 4" : "span 5"}
          gridRow="span 1"
          backgroundColor={colors.grey[100]}
          sx={{ borderRadius: 2 }}
        >
          <Box
            p="4px 12px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="h5"
              fontWeight="600"
              color={colors.primary[900]}
            >
              {`${t("dashboard.charts.lastPeriodAmountPieChart")} ` +
                (periodSelected === "7 Days"
                  ? `${t("dashboard.charts.week")}`
                  : periodSelected === "30 Days"
                  ? `${t("dashboard.charts.month")}`
                  : `${t("dashboard.charts.semester")}`)}
            </Typography>
          </Box>
          <Box height="250px" mt="20px" p="4px">
            <PieChart
              data={pieAmountData}
              dolar={true}
              CustomLayerComponent={CenterValuePieChart(true)}
            />
          </Box>
        </Box>
        <Box
          gridColumn={isNonMobile ? "span 4" : "span 5"}
          gridRow="span 1"
          backgroundColor={colors.grey[100]}
          sx={{ borderRadius: 2 }}
        >
          <Box
            p="4px 12px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="h5"
              fontWeight="600"
              color={colors.primary[900]}
            >
              {`${t("dashboard.charts.lastPeriodQuantityPieChart")} ` +
                (periodSelected === "7 Days"
                  ? `${t("dashboard.charts.week")}`
                  : periodSelected === "30 Days"
                  ? `${t("dashboard.charts.month")}`
                  : `${t("dashboard.charts.semester")}`)}
            </Typography>
          </Box>
          <Box height="250px" mt="20px" p="4px">
            <PieChart
              data={pieQuantityData}
              dolar={false}
              CustomLayerComponent={CenterValuePieChart(false)}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
