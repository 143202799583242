import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Image from "../register/Design-sem-nome.jpg";
import msb from "../register/logo.png";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Box, Typography } from "@mui/material";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "../../api/axios";
import Toast from "../../components/Snackbar";

const REGISTER_URL = "/users/manager";
const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const PASS_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

const Register = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { leadId } = useParams();
  const [priceId, setPriceId] = useState("");

  const [name, setName] = useState("");

  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(true);

  const [phoneNumber, setPhoneNumber] = useState("");

  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);

  const [matchPassword, setMatchPassword] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");

  useEffect(() => {
    const getLeads = async () => {
      try {
        const lead = await axios.get(`/leads/${leadId}`);
        setName(lead.data.name);
        setEmail(lead.data.email);
        setPhoneNumber(lead.data.phoneNumber);
        setPriceId(lead.data.priceId);
      } catch (error) {}
    };
    getLeads();
  }, []);

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email) || !email);
  }, [email]);

  useEffect(() => {
    setValidPassword(PASS_REGEX.test(password) || !password);
    setValidMatch(password === matchPassword);
  }, [password, matchPassword]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack
    const v1 = name;
    const v2 = email;
    const v3 = password;
    if (!v1 || !v2 || !v3) {
      setAlertMessage("Missing Information");
      setSeverity("warning");
      setOpenAlert(true);
      return;
    }

    try {
      const response = await axios.post(
        REGISTER_URL,
        JSON.stringify({
          name,
          email,
          password,
          phoneNumber,
          role: "MANAGER",
          price_id: priceId,
        }),
        {
          headers: { "Content-Type": "application/json" },
          //withCredentials: true,
        }
      );

      if (response.status === 200) {
        setAlertMessage("Registrado com Sucesso");
        setSeverity("success");
        setOpenAlert(true);
        window.location.replace(response.data.urlCheckout);
      }
    } catch (err) {
      if (!err?.response) {
        setAlertMessage("No Server Response");
        setSeverity("error");
        setOpenAlert(true);
      } else if (err.response?.status === 405) {
        setAlertMessage("Esse e-mail Já está cadastrado");
        setSeverity("warning");
        setOpenAlert(true);
      } else {
        setAlertMessage(
          "Tivemos um problema. Verifique seus dados e tente novamente"
        );
      }
    }
  };

  return (
    <>
      <Toast
        open={openAlert}
        setOpen={setOpenAlert}
        severity={severity}
        alertMessage={alertMessage}
      />
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${Image})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
          backgroundColor={colors.secondary}
        >
          <Box
            sx={{
              display: "flex",
              my: 4,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {<img src={msb} height="70px" alt="logo" />}
            <Typography sx={{ mt: "12px" }} fontWeight="bold">
              {email}
            </Typography>

            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1, width: "80%" }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Nome Completo"
                name="name"
                autoComplete="off"
                //onChange={(e) => setName(e.target.value)}
                value={name}
                //autoFocus
              />
              <TextField
                margin="normal"
                fullWidth
                id="phoneNumber"
                label="Telefone"
                name="phoneNumber"
                autoComplete="off"
                onChange={(e) => setPhoneNumber(e.target.value)}
                value={phoneNumber}
              />
              <Typography marginTop={"12px"}>Crie uma senha:</Typography>
              <TextField
                error={!validPassword}
                margin="normal"
                required
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                autoComplete="off"
                onFocus={() => setPasswordFocus(true)}
                onBlur={() => setPasswordFocus(false)}
              />
              <TextField
                error={!validMatch & !matchFocus}
                margin="normal"
                required
                fullWidth
                name="confirm-password"
                label="Confirmação de Senha"
                type="password"
                id="confirm-password"
                onChange={(e) => setMatchPassword(e.target.value)}
                value={matchPassword}
                autoComplete="off"
                onFocus={() => setMatchFocus(true)}
                onBlur={() => setMatchFocus(false)}
              />
              <p id="pwdnote">
                <FontAwesomeIcon icon={faInfoCircle} />
                <br />
                Precisa conter: <br />
                -8 a 24 caracteres
                <br />
                -Letra maiúscula
                <br />
                -Letra minúscula
                <br />
                -Um número
                <br />
                -Um caractere especial:{" "}
                <span aria-label="exclamation mark">!</span>{" "}
                <span aria-label="at symbol">@</span>{" "}
                <span aria-label="hashtag">#</span>{" "}
                <span aria-label="dollar sign">$</span>{" "}
                <span aria-label="percent">%</span>
              </p>
              <Button
                disabled={
                  !email ||
                  !password ||
                  !name ||
                  !validEmail ||
                  !validPassword ||
                  !validMatch
                }
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                CADASTRAR
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Register;
