import {
  Box,
  Button,
  TextField,
  useTheme,
  Autocomplete,
  Typography,
  Paper,
  Link,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useState, useEffect, useContext } from "react";
import { storeContext } from "../../context/storeContext";
import { tokens } from "../../theme";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputAdornment from "@mui/material/InputAdornment";
import dayjs from "dayjs";
import { NumericFormat } from "react-number-format";
import useAxiosPrivate from "../../api/useAxiosPrivate";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import ClientDialog from "../../components/ClientDialog";
import EditClientDialog from "../../components/EditClientDialog";
import RecipientDialog from "../../components/NewRecipientDialog";
import EditRecipientDialog from "../../components/EditRecipientDialog";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import ProviderLimitDialog from "../../components/ProviderLimitDialog";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { summarizeClientTransactions } from "./dataProcessing";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import countriesJSON from "../../utils/countryList/countries.json";
import { red } from "@mui/material/colors";

const today = dayjs();

const NewTransaction = (props) => {
  const formData = new FormData();
  //Configurações
  const NEW_TRANSACTION_URL = "/transactions";
  const axiosPrivate = useAxiosPrivate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { t } = useTranslation();
  let USDollar = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 });

  const {
    handleClose,
    stores,
    storeProviders,
    setAlertMessage,
    setOpenAlert,
    setSeverity,
  } = props;

  //Informações selecionáveis
  const [clients, setClients] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const countries = countriesJSON;
  const [clientRecipients, setClientRecipients] = useState([]);
  const paymentMethods = [
    { id: 1, name: "Cash" },
    { id: 2, name: "Link Remoto" },
    { id: 3, name: "Cartão de Débito" },
    { id: 4, name: "Outro" },
  ];

  const [storesList, setStoresList] = useContext(storeContext);

  //Informações da Transação
  const [store, setStore] = useState(stores.length === 1 ? stores[0] : null);
  const [client, setClient] = useState(null);
  const [clientInfo, setClientInfo] = useState(null);
  const [recipient, setRecipient] = useState(null);
  const [recipientInfo, setRecipientInfo] = useState(null);
  const [amountSent, setAmountSent] = useState(null);
  const [amountCollected, setAmountCollected] = useState(0);
  const [floatAmountSent, setFloatAmountSent] = useState(0);
  const [fee, setFee] = useState(null);

  const [floatFee, setFloatFee] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [provider, setProvider] = useState(null);
  const [dateTransaction, setDateTransaction] = useState(today);
  const [destinyCountry, setDestinyCountry] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState(false);

  // Permitir a atualização do Número de Telefone e Cidade de Cliente
  const [clientPhoneNumber, setClientPhoneNumber] = useState("");
  const [clientPhoneNumberChanged, setClientPhoneNumberChanged] =
    useState(false);
  const [clientCity, setClientCity] = useState("");
  const [clientCityChanged, setClientCityChanged] = useState(false);

  //Abertura de Caixas de Diálogo
  const [openNewClientDialog, setOpenNewClientDialog] = useState(false);
  const [openEditClientDialog, setOpenEditClientDialog] = useState(false);
  const [openNewRecipientDialog, setOpenNewRecipientDialog] = useState(false);
  const [openEditRecipientDialog, setOpenEditRecipientDialog] = useState(false);
  const [openProviderLimitDialog, setOpenProviderLimitDialog] = useState(false);

  //Informações históricas
  const [tableViewSelect, setTableViewSelect] = useState("Transactions");
  const [clientTransactionsTotal, setClientTransactionsTotal] = useState([]);
  const [clientTransactionsByProvider, setClientTransactionsByProvider] =
    useState([]);
  const [clientLimitTransaction, setClientLimitTransaction] = useState([]);

  //Fechar Caixas de Diálogo
  const handleCloseProviderLimitDialog = () => {
    setOpenProviderLimitDialog(false);
  };
  const handleCloseInternalClientDialog = () => {
    setOpenNewClientDialog(false);
    setOpenEditClientDialog(false);
  };
  const handleCloseInternalRecipientDialog = () => {
    setOpenNewRecipientDialog(false);
    setOpenEditRecipientDialog(false);
    getRecipients(client.id);
    setRecipient(null);
  };

  useEffect(() => {
    const getClients = async () => {
      try {
        const response = await axiosPrivate.get("/clients");
        setClients(response.data);
        setClient(null);
      } catch (err) {}
    };

    getClients();
  }, [openEditClientDialog, openNewClientDialog]);

  const getRecipients = async (clientId) => {
    try {
      const response = await axiosPrivate.get(
        `/recipients/clients/${clientId}`
      );
      setClientRecipients(response.data);
    } catch (err) {}
  };

  const getTransactions = async (clientId, storeId) => {
    var storeProviders = [];

    try {
      const response = await axiosPrivate.get("/storeProvider/storeChain");

      if (!storeId) {
        storeProviders = response.data.filter(
          (provider) => provider.storeId === store.id
        );
      } else {
        storeProviders = response.data.filter(
          (provider) => provider.storeId === storeId
        );
      }
    } catch (err) {}

    try {
      const response = await axiosPrivate.get(
        `/transactions/client/${clientId}`
      );

      if (!storeId) {
        summarizeClientTransactions(
          response.data.filter(
            (transaction) => transaction.storeId === store.id
          ),
          storeProviders,
          setClientTransactionsByProvider,
          setClientTransactionsTotal,
          setClientLimitTransaction
        );
      } else {
        summarizeClientTransactions(
          response.data.filter(
            (transaction) => transaction.storeId === storeId
          ),
          storeProviders,
          setClientTransactionsByProvider,
          setClientTransactionsTotal,
          setClientLimitTransaction
        );
      }
    } catch (err) {}
  };

  const changeTransactionTableView = () => {
    setTableViewSelect("Transactions");
  };

  const changeLimitsTableView = () => {
    setTableViewSelect("Limits");
  };
  /*
  const handleFileChange = (event) => {
    let allowed = true;
    const files = event.target.files;

    console.log(files);

    Array.from(files).forEach((file) => {
      if (file.size / 1024 / 1024 >= 4) {
        setAlertMessage("Cada arquivo deve ter no máximo 4MB");
        setSeverity("warning");
        setOpenAlert(true);
        allowed = false;
        return;
      }
    });

    if (Array.from(files).length > 3) {
      setAlertMessage("Você pode anexar somente 3 Arquivos");
      setSeverity("warning");
      setOpenAlert(true);
      allowed = false;
      return;
    }
    allowed ? setSelectedFiles(files) : setSelectedFiles(null);
  };
*/
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !store ||
      !client ||
      !recipient ||
      !amountCollected ||
      !provider ||
      !dateTransaction
    ) {
      setAlertMessage("Verfifique os campos obrigatórios");
      setSeverity("warning");
      setOpenAlert(true);
      return;
    }

    //setLoadingFiles(true);

    formData.append("storeId", store.id);
    formData.append("clientId", client.id);
    formData.append("destinyCountry", destinyCountry?.country || "Unknown");
    formData.append("recipientId", recipient.id);
    formData.append("storeProviderId", provider.id);
    formData.append("amountCollected", amountCollected);
    formData.append("amountSent", floatAmountSent);
    formData.append("paymentMethod", paymentMethod?.name || "");
    formData.append("fee", floatFee);
    formData.append("dateTransaction", dateTransaction);
    /*if (selectedFiles) {
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append("files", selectedFiles[i]);
      }
    }
*/
    try {
      const response = await axiosPrivate.post(NEW_TRANSACTION_URL, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        credentials: "include",
        withCredentials: true,
      });
      if (response.status === 201) {
        setAlertMessage(`Remessa registrada!`);
        setSeverity("success");
        setOpenAlert(true);
        handleClose();
      }
    } catch (err) {
      if (!err?.response) {
        setAlertMessage(
          "O servidor não está respondendo. Por favor tente novamente mais tarde."
        );
        setSeverity("error");
        setOpenAlert(true);
      } else if (err.response?.status === 400) {
        setAlertMessage(err.response?.data.message || "Erro");
        setSeverity("error");
        setOpenAlert(true);
      } else {
        setAlertMessage("Falha no Registro da Remessa");
        setSeverity("error");
        setOpenAlert(true);
      }
    }
  };

  const handleEditClientInfo = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("phoneNumber", clientPhoneNumber);
    formData.append("city", clientCity);

    try {
      const response = await axiosPrivate.putForm(
        `/clients/${clientInfo.id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          credentials: "include",
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setAlertMessage(
          `${t("clients.editClient.successAlertMessage")}: ${
            response.data.name
          }`
        );
        setSeverity("success");
        setOpenAlert(true);
        client.phoneNumber = clientPhoneNumber;
        client.city = clientCity;
        setClientPhoneNumberChanged(false);
        setClientCityChanged(false);
      }
    } catch (err) {
      if (!err?.response) {
        setAlertMessage(t("clients.editClient.serverFailAlertMessage"));
        setSeverity("error");
        setOpenAlert(true);
      } else if (err.response?.status === 400) {
        setAlertMessage(
          err.response?.data.message ||
            t("clients.editClient.serverFailAlertMessage")
        );
        setSeverity("error");
        setOpenAlert(true);
      } else {
        setAlertMessage(t("clients.editClient.otherFailAlertMessage"));
        setSeverity("error");
        setOpenAlert(true);
      }
    }
  };

  /*const handleProfitSelect = (value) => {
    setProfit(value);
  };
  const handleQuotationProvider = (value) => {
    setQuotationProvider(value);
    setSpread(0);
    setProfit(0);
  };
  const handleQuotationClient = (value) => {
    setQuotationClient(value);
    if ((quotationProvider > 0) & (value > 0)) {
      setSpread(quotationProvider - value);
      if (floatAmount - fee <= provider.providerFeeLowerTreshold) {
        setProfit(
          ((quotationProvider - value) * (floatAmount - fee)) /
            quotationProvider +
            (fee - provider.providerFeeLower)
        );
      } else {
        setProfit(
          ((quotationProvider - value) * (floatAmount - fee)) /
            quotationProvider +
            (fee - provider.providerFee)
        );
      }
    } else {
      setSpread(0);
      setProfit(0);
    }
  };
  const handleSpreadSelect = (value) => {
    setSpread(value);
    if (
      quotationProvider > 0 &&
      (!quotationClient || quotationClient == 0) &&
      (!profit || profit == 0)
    ) {
      setQuotationClient(quotationProvider - value);
      if (floatAmount - fee <= provider.providerFeeLowerTreshold) {
        setProfit(
          (value * (floatAmount - fee)) / quotationProvider +
            (fee - provider.providerFeeLower)
        );
      } else {
        setProfit(
          (value * (floatAmount - fee)) / quotationProvider +
            (fee - provider.providerFee)
        );
      }
    }
  };

*/

  const handleClientSelect = (client) => {
    setClient(client);
    setClientInfo(client);
    getRecipients(client.id);
    setRecipient(null);
    getTransactions(client.id);
    setClientPhoneNumber(client.phoneNumber || "");
    setClientCity(client.city || "");
  };

  const handleRecipientSelect = (recipient) => {
    setRecipient(recipient);
    setRecipientInfo(recipient);
  };

  return (
    <Box>
      <Header
        title="NOVA REMESSA"
        subtitle="Cadastre uma nova Remessa"
        button={false}
        color="#9f1853"
      />

      <Box
        borderRadius="4px"
        padding="8px"
        backgroundColor={colors.grey[100]}
        display="grid"
        gap="8px"
        gridTemplateColumns="repeat(4, 1fr)"
        sx={{
          "& > div": { gridColumns: "span 4" },
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ gridColumn: "span 4" }}
        >
          Loja
        </Typography>
        <Autocomplete
          fullWidth
          className="autocomplete"
          required
          value={store}
          disableClearable
          variant="outlined"
          id="store"
          options={stores}
          getOptionLabel={(option) => option.name}
          sx={{
            gridColumn: isNonMobile ? "span 2" : "span 4",
          }}
          onChange={(_, newValue) => {
            setStore(newValue);
            setProvider(null);
            if (client) getTransactions(client.id, newValue.id);
          }}
          renderInput={(params) => (
            <TextField {...params} size="small" label="Loja" />
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
      </Box>

      {/*INFORMAÇÕES DO CLIENTE*/}
      {store && (
        <Box
          borderRadius="4px"
          marginTop="8px"
          padding="8px"
          backgroundColor={colors.grey[100]}
          display="grid"
          gap="4px"
          gridTemplateColumns="repeat(4, 1fr)"
          sx={{
            "& > div": { gridColumns: "span 4" },
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            gridColumn="span 4"
          >
            <Typography variant="h4" fontWeight="bold">
              Cliente
            </Typography>
            <Box display="flex" gap={"10px"}>
              <Button
                sx={{
                  backgroundColor: colors.primary[500],
                  color: colors.grey[100],
                  width: "100px",
                  ...(client && { width: "50px" }),
                  "&:hover": {
                    backgroundColor: colors.primary[700],
                    color: "white",
                  },
                }}
                id="new-client"
                onClick={setOpenNewClientDialog}
              >
                <AddCircleOutlinedIcon
                  sx={{ mr: "10px", ...(client && { mr: "0px" }) }}
                />
                {!client && <Typography fontWeight="bold">Novo</Typography>}
              </Button>

              <Button
                sx={{
                  backgroundColor: colors.primary[500],
                  color: colors.grey[100],
                  "&:hover": {
                    backgroundColor: colors.primary[700],
                    color: "white",
                  },
                  width: "50px",
                  ...(client && { width: "100px" }),
                }}
                id="edit-client"
                onClick={setOpenEditClientDialog}
                disabled={!client}
              >
                <ModeEditOutlineOutlinedIcon
                  sx={{ mr: "10px", ...(!client && { mr: "0px" }) }}
                />
                {client && <Typography fontWeight="bold">Editar</Typography>}
              </Button>
            </Box>
          </Box>

          <Box sx={{ gridColumn: "span 4" }} />

          <Autocomplete
            fullWidth
            size="small"
            value={client}
            onChange={(event, newCliente) => {
              handleClientSelect(newCliente);
            }}
            disableClearable
            variant="outlined"
            id="nome-cliente"
            options={clients}
            getOptionLabel={(option) =>
              `${option.name} ${
                option.phoneNumber !== "NÃO INFORMADO" && option.phoneNumber
                  ? option.phoneNumber
                  : ""
              }`
            }
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
            renderInput={(params) => (
              <TextField {...params} label="Nome do Cliente" />
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          {client && (
            <>
              <TextField
                size="small"
                fullWidth
                value={clientPhoneNumber}
                onChange={(e) => {
                  setClientPhoneNumber(e.target.value);
                  setClientPhoneNumberChanged(true);
                }}
                variant="outlined"
                type="text"
                label="Telefone"
                //name="Telefone"
                sx={{
                  gridColumn: isNonMobile ? "span 1" : "span 2",
                }}
                InputProps={{
                  endAdornment: clientPhoneNumberChanged && (
                    <InputAdornment position="start">
                      <IconButton onClick={handleEditClientInfo}>
                        <SaveIcon />{" "}
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setClientPhoneNumber(client.phoneNumber);
                          setClientPhoneNumberChanged(false);
                        }}
                      >
                        <CloseIcon />{" "}
                      </IconButton>
                    </InputAdornment>
                  ),
                  sx: {
                    "& input": { textAlign: "left" },
                    backgroundColor: colors.primary[100],
                  },
                }}
              />
              <TextField
                size="small"
                fullWidth
                value={clientCity}
                onChange={(e) => {
                  setClientCity(e.target.value);
                  setClientCityChanged(true);
                }}
                variant="outlined"
                type="text"
                label="Cidade"
                InputProps={{
                  endAdornment: clientCityChanged && (
                    <InputAdornment position="start">
                      <IconButton onClick={handleEditClientInfo}>
                        <SaveIcon />{" "}
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setClientCity(client.city || "");
                          setClientCityChanged(false);
                        }}
                      >
                        <CloseIcon />{" "}
                      </IconButton>
                    </InputAdornment>
                  ),
                  sx: {
                    "& input": { textAlign: "left" },
                    backgroundColor: colors.primary[100],
                  },
                }}
                sx={{
                  gridColumn: isNonMobile ? "span 1" : "span 2",
                }}
              />
            </>
          )}

          {client && (
            <>
              <Box
                marginTop="20px"
                sx={{ gridColumn: "span 4" }}
                display="flex"
                justifyContent="space-between"
                alignContent="center"
              >
                <Typography fontWeight="bold">
                  {`${store.name} - Resumo das Remessas`}{" "}
                </Typography>
                <Box
                  display="flex"
                  justifyContent="space-around"
                  gridColumn={isNonMobile ? "span 5" : "span 10"}
                >
                  {clientTransactionsByProvider.length > 0 ? (
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label="Transações"
                        onClick={changeTransactionTableView}
                        sx={{
                          backgroundColor:
                            tableViewSelect === "Transactions"
                              ? colors.primary[500]
                              : colors.grey[200],
                          color:
                            tableViewSelect === "Transactions"
                              ? colors.grey[100]
                              : colors.primary[900],
                        }}
                      />
                      <Chip
                        label="Alerta de Documentação"
                        onClick={changeLimitsTableView}
                        sx={{
                          backgroundColor:
                            tableViewSelect === "Limits"
                              ? colors.primary[500]
                              : colors.grey[200],
                          color:
                            tableViewSelect === "Limits"
                              ? colors.grey[100]
                              : colors.primary[900],
                        }}
                      />
                    </Stack>
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>

              <Box
                display="flex"
                justifyContent="space-around"
                gap="20px"
                sx={{ gridColumn: "span 4" }}
              >
                {clientTransactionsByProvider.length < 1 ? (
                  <Typography
                    sx={{ gridColumn: isNonMobile ? "span 4" : "span 8" }}
                  >
                    Não há Remessa para o cliente nesta Loja
                  </Typography>
                ) : tableViewSelect === "Transactions" ? (
                  <TableContainer
                    component={Paper}
                    sx={{ gridColumn: "span 8", maxHeight: 200 }}
                  >
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">
                            <b>Provedora</b>
                          </TableCell>
                          <TableCell align="left">
                            <b>7 Dias</b>
                          </TableCell>
                          <TableCell align="left">
                            <b>30 Dias</b>
                          </TableCell>
                          <TableCell align="left">
                            <b>365 Dias</b>
                          </TableCell>
                          <TableCell align="left">
                            <b>Total</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {clientTransactionsByProvider.map((row) => (
                          <TableRow
                            key={row.providerName}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell>{row.providerName}</TableCell>
                            <TableCell>{`USD ${USDollar.format(
                              row.last7Days
                            )}`}</TableCell>
                            <TableCell>{`USD ${USDollar.format(
                              row.last30Days
                            )}`}</TableCell>
                            <TableCell>{`USD ${USDollar.format(
                              row.lastYear
                            )}`}</TableCell>
                            <TableCell>{`USD ${USDollar.format(
                              row.total
                            )}`}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">
                            <b>TOTAL</b>
                          </TableCell>
                          <TableCell align="left">
                            <b>{`USD ${USDollar.format(
                              clientTransactionsTotal.last7Days
                            )}`}</b>
                          </TableCell>
                          <TableCell align="left">
                            <b>{`USD ${USDollar.format(
                              clientTransactionsTotal.last30Days
                            )}`}</b>
                          </TableCell>
                          <TableCell align="left">
                            <b>{`USD ${USDollar.format(
                              clientTransactionsTotal.lastYear
                            )}`}</b>
                          </TableCell>
                          <TableCell align="left">
                            <b>{`USD ${USDollar.format(
                              clientTransactionsTotal.total
                            )}`}</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                ) : (
                  <Box disply="flex" flexDirection="column" width="100%">
                    <Box p="4px">
                      <p>
                        Esses valores indicam o ponto a partir do qual é
                        necessário solicitar documentação adicional para atender
                        as regulamentaçōes. Use-os para garantir a conformidade
                        em suas transações. Para mais informaçōes{" "}
                        <a
                          style={{
                            color: colors.primary[500],
                            "&:visited": {
                              color: colors.primary[700],
                            },
                          }}
                          href="https://msbmanagerkb.tawk.help/article/transaction-history-and-documentation-alerts"
                          target="_blank"
                        >
                          clique aqui
                        </a>{" "}
                        para entender melhor como usar essas informaçōes para
                        deixar sua loja mais segura{" "}
                      </p>
                    </Box>

                    <TableContainer
                      component={Paper}
                      sx={{ gridColumn: "span 8", maxHeight: 200 }}
                    >
                      <Table size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">
                              <b>Provedora</b>
                            </TableCell>
                            <TableCell align="left">
                              <b>Limite</b>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {clientLimitTransaction.map((row) => (
                            <TableRow
                              key={row.provider}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>{row.provider}</TableCell>
                              <TableCell>{`USD ${USDollar.format(
                                row.limit
                              )}`}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Typography p="8px">
                      As companhias de remessa se reservam o direito de
                      solicitar documentos adicionais a qualquer momento,
                      independentemente do valor da remessa.
                    </Typography>
                  </Box>
                )}
              </Box>
            </>
          )}
        </Box>
      )}
      {/* PARTE DO BENEFICIÁRO */}
      {client && (
        <Box
          borderRadius="10px"
          padding="20px"
          marginTop="20px"
          marginBottom="20px"
          backgroundColor={colors.grey[100]}
          display="grid"
          gridTemplateColumns="repeat(4, 1fr)"
          gap="15px"
          sx={{
            "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            gridColumn="span 4"
          >
            <Typography variant="h4" fontWeight="bold">
              Beneficiário
            </Typography>
            <Box display="flex" gap={"10px"}>
              <Button
                sx={{
                  backgroundColor: colors.primary[500],
                  color: colors.grey[100],
                  "&:hover": {
                    backgroundColor: colors.primary[700],
                    color: "white",
                  },
                  width: "100px",
                  ...(recipient && { width: "50px" }),
                }}
                id="new-recipient"
                onClick={setOpenNewRecipientDialog}
              >
                <AddCircleOutlinedIcon
                  sx={{ mr: "10px", ...(recipient && { mr: "0px" }) }}
                />
                {!recipient && <Typography fontWeight="bold">Novo</Typography>}
              </Button>

              <Button
                sx={{
                  backgroundColor: colors.primary[500],
                  color: colors.grey[100],
                  "&:hover": {
                    backgroundColor: colors.primary[700],
                    color: "white",
                  },
                  width: "50px",
                  ...(client && { width: "100px" }),
                }}
                id="edit-client"
                onClick={setOpenEditRecipientDialog}
                disabled={!recipient}
              >
                <ModeEditOutlineOutlinedIcon
                  sx={{ mr: "10px", ...(!recipient && { mr: "0px" }) }}
                />
                {recipient && <Typography fontWeight="bold">Editar</Typography>}
              </Button>
            </Box>
          </Box>

          <Box sx={{ gridColumn: "span 4" }} visibility="hidden" />
          <Autocomplete
            value={recipient}
            onChange={(_, newRecipient) => {
              handleRecipientSelect(newRecipient);
            }}
            disableClearable
            variant="outlined"
            id="recipient-name"
            options={clientRecipients}
            getOptionLabel={(option) => `${option.name}`}
            sx={{ gridColumn: "span 4" }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label="Nome do Beneficiário"
              />
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          {recipient?.otherInformations && (
            <TextField
              size="small"
              fullWidth
              value={recipient.otherInformations}
              variant="outlined"
              type="text"
              label="Outras Informações"
              sx={{
                backgroundColor: colors.grey[100],
                gridColumn: "span 4",
              }}
              inputProps={{ readOnly: true }}
            />
          )}
        </Box>
      )}

      {/* PARTE DA REMESSA */}

      {recipient && (
        <>
          <Box
            borderRadius="10px"
            padding="8px"
            marginTop="8px"
            backgroundColor={colors.grey[100]}
            display="grid"
            gap="12px"
            sx={{
              "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
              gridTemplateColumns: "repeat(6, 1fr)",
            }}
          >
            <Box sx={{ gridColumn: "span 6" }}>
              <Typography variant="h4" fontWeight="bold">
                Remessa
              </Typography>
            </Box>

            <DatePicker
              disableFuture
              label="Data da Remessa (AAAA-MM-DD)"
              value={dateTransaction}
              defaultValue={today.date()}
              timezone="system"
              onChange={(newValue) => setDateTransaction(dayjs(newValue))}
              sx={{ gridColumn: isNonMobile ? "span 2" : "span 3" }}
              format="YYYY-MM-DD"
            />
            <Autocomplete
              fullWidth
              value={destinyCountry}
              variant="outlined"
              id="country"
              options={countries}
              getOptionLabel={(option) => option.country}
              sx={{ gridColumn: isNonMobile ? "span 2" : "span 3" }}
              //key={(option) => option.country_code}
              onChange={(_, newValue) => setDestinyCountry(newValue)}
              renderInput={(params) => (
                <TextField {...params} label="País de Destino" />
              )}
              isOptionEqualToValue={(option, value) =>
                option.country_code === value.country_code
              }
            />
            <Autocomplete
              fullWidth
              required
              value={provider}
              disableClearable
              variant="outlined"
              id="provider"
              options={storeProviders
                .filter((provider) => provider.storeId === store.id)
                .filter((provider) => provider.active)}
              getOptionLabel={(option) => option.Provider.name}
              sx={{ gridColumn: isNonMobile ? "span 2" : "span 3" }}
              onChange={(_, newValue) => setProvider(newValue)}
              renderInput={(params) => (
                <TextField {...params} label="Provedora *" />
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />

            <NumericFormat
              decimalScale={2}
              thousandSeparator
              value={amountSent}
              customInput={TextField}
              required
              id="amount"
              variant="outlined"
              type="text"
              label="Valor da Remessa sem Taxa"
              sx={{ gridColumn: isNonMobile ? "span 2" : "span 3" }}
              onValueChange={(e) => {
                setAmountSent(USDollar.format(e.value));
                setFloatAmountSent(e.floatValue);
                setAmountCollected(e.floatValue + floatFee);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">USD </InputAdornment>
                ),
                sx: { "& input": { textAlign: "right" } },
              }}
            />
            <NumericFormat
              decimalScale={2}
              fixedDecimalScale
              thousandSeparator
              value={fee}
              customInput={TextField}
              required
              id="fee"
              variant="outlined"
              type="text"
              label="Taxa"
              sx={{ gridColumn: isNonMobile ? "span 2" : "span 3" }}
              onValueChange={(e) => {
                setFee(e.value);
                setFloatFee(e.floatValue);
                setAmountCollected(floatAmountSent + e.floatValue);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">USD </InputAdornment>
                ),
                sx: { "& input": { textAlign: "right" } },
              }}
            />

            <NumericFormat
              decimalScale={2}
              fixedDecimalScale
              thousandSeparator
              value={amountCollected}
              customInput={TextField}
              id="valueSent"
              variant="outlined"
              type="text"
              label="Valor Coletado"
              sx={{
                gridColumn: isNonMobile ? "span 2" : "span 3",
                backgroundColor: colors.grey[200],
              }}
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">USD </InputAdornment>
                ),
                sx: { "& input": { textAlign: "right" } },
              }}
            />
            <Box
              sx={{
                gridColumn: isNonMobile ? "span 4" : "span 3",
              }}
            />

            <Autocomplete
              fullWidth
              value={paymentMethod}
              //disableClearable
              variant="outlined"
              id="payment-method"
              options={paymentMethods}
              getOptionLabel={(option) => option.name}
              sx={{ gridColumn: isNonMobile ? "span 2" : "span 3" }}
              onChange={(_, newValue) => setPaymentMethod(newValue)}
              renderInput={(params) => (
                <TextField {...params} label="Forma de Pagamento" />
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
            {/*
          <NumericFormat
            disabled={amount <= 0 || !fee}
            decimalScale={2}
            fixedDecimalScale
            thousandSeparator
            value={quotationProvider}
            customInput={TextField}
            id="quotation-provider"
            variant="outlined"
            type="text"
            label="Cotação da Provedora"
            sx={{ gridColumn: isNonMobile ? "span 1" : "span 4" }}
            onValueChange={(e) => handleQuotationProvider(e.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {destinyCountry?.iso_code || ""}{" "}
                </InputAdornment>
              ),
              sx: { "& input": { textAlign: "right" } },
            }}
          />
          <Box
            display="grid"
            rowGap={isNonMobile ? "0px" : "15px"}
            columnGap="15px"
            gridTemplateColumns="repeat(3, 1)"
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          >
            <Box sx={{ gridColumn: isNonMobile ? "span 3" : "span 4" }} />
            <NumericFormat
              disabled={
                amount <= 0 || !quotationProvider || quotationProvider == 0
              }
              decimalScale={2}
              backgroundColor={"blue"}
              thousandSeparator
              value={quotationClient}
              customInput={TextField}
              id="quotation-client"
              variant="standard"
              type="text"
              label="Cotação do Cliente"
              sx={{
                gridColumn: isNonMobile ? "span 1" : "span 4",
                marginLeft: isNonMobile ? "20px" : "Opx",
              }}
              onValueChange={(e) => handleQuotationClient(e.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {destinyCountry?.iso_code || ""}{" "}
                  </InputAdornment>
                ),
                sx: { "& input": { textAlign: "right" } },
              }}
            />
            {isNonMobile && (
              <Typography
                align="center"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                sx={{ gridColumn: isNonMobile ? "span 1" : "span 1" }}
              >
                ou{" "}
              </Typography>
            )}
            <NumericFormat
              disabled={quotationClient > 0 || !amount || amount <= 0}
              decimalScale={2}
              thousandSeparator
              value={spread}
              customInput={TextField}
              required
              id="spread"
              variant="standard"
              type="text"
              label="Spread"
              sx={{
                gridColumn: isNonMobile ? "span 1" : "span 4",
                marginRight: isNonMobile ? "20px" : "0px",
              }}
              //onChange={(event) => setSpread(event.target.value)}
              onBlur={(event) => handleSpreadSelect(event.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {destinyCountry?.iso_code || ""}
                  </InputAdornment>
                ),
                sx: { "& input": { textAlign: "right" } },
              }}
            />
          </Box>
          <NumericFormat
            decimalScale={2}
            disabled={amount <= 0}
            thousandSeparator
            value={profit}
            customInput={TextField}
            required
            id="profit"
            variant="outlined"
            type="text"
            label="Lucro"
            sx={{ gridColumn: isNonMobile ? "span 1" : "span 4" }}
            onValueChange={(e) => handleProfitSelect(e.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">USD </InputAdornment>
              ),
              sx: { "& input": { textAlign: "right" } },
            }}
          />*/}
          </Box>
          {/*
          <Box
            borderRadius="10px"
            padding="8px"
            marginTop="8px"
            backgroundColor={colors.grey[100]}
            display="grid"
            gap="12px"
            sx={{
              "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
              gridTemplateColumns: "repeat(6, 1fr)",
            }}
          >
            <Box sx={{ gridColumn: "span 6" }}>
              <Typography variant="h4" fontWeight="bold">
                Arquivos Anexados
              </Typography>
            </Box>
            <Button
              variant="contained"
              component="label"
              startIcon={<CloudUploadOutlinedIcon />}
              sx={{
                backgroundColor: colors.primary[500],
                color: colors.grey[100],
                "&:hover": {
                  backgroundColor: colors.primary[600],
                  color: "white",
                },
                gridColumn: isNonMobile ? "span 1" : "span 2",
              }}
            >
              <Typography fontWeight="bold">Arquivos</Typography>
              <input
                type="file"
                name="files"
                hidden
                onChange={handleFileChange}
                multiple
              />
            </Button>
            {Array.from(selectedFiles).map((file, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography>{file.name}</Typography>
              </Box>
            ))}
          </Box>
          */}
        </>
      )}
      <Box height="20px">
        <Box
          borderRadius="10px"
          padding="10px"
          marginTop="20px"
          marginBottom="20px"
          backgroundColor={colors.grey[100]}
          display="grid"
          gap="5px"
          sx={{
            "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
            gridTemplateColumns: "repeat(4, 1fr)",
          }}
        >
          <Box sx={{ gridColumn: "span 4" }} />
          <Box sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }} />
          <Button
            type="submit"
            disabled={
              !store ||
              !client ||
              !recipient ||
              !amountCollected ||
              !amountSent ||
              !fee ||
              !provider ||
              !dateTransaction ||
              loadingFiles
            }
            sx={{
              backgroundColor: colors.primary[500],
              color: colors.grey[100],
              "&:hover": {
                backgroundColor: colors.primary[600],
                color: "white",
              },
              gridColumn: isNonMobile ? "span 1" : "span 2",
            }}
            variant="contained"
            height="100%"
            onClick={handleSubmit}
          >
            {loadingFiles ? (
              <Typography fontWeight="bold">Carregando Arquivos...</Typography>
            ) : (
              <Typography fontWeight="bold">Registrar Remessa</Typography>
            )}
          </Button>
          <Button
            sx={{
              backgroundColor: red[700],
              color: colors.grey[100],
              "&:hover": {
                backgroundColor: red[600],
                color: "white",
              },
              gridColumn: isNonMobile ? "span 1" : "span 2",
            }}
            variant="contained"
            height="100%"
            onClick={handleClose}
          >
            <Typography fontWeight="bold">Cancelar</Typography>
          </Button>
        </Box>
      </Box>
      <Box />

      <ClientDialog
        openNewClientDialog={openNewClientDialog}
        handleClose={handleCloseInternalClientDialog}
        setAlertMessage={setAlertMessage}
        setSeverity={setSeverity}
        setOpenAlert={setOpenAlert}
      />
      <EditClientDialog
        openEditClientDialog={openEditClientDialog}
        handleClose={handleCloseInternalClientDialog}
        clientInfo={clientInfo}
        setAlertMessage={setAlertMessage}
        setSeverity={setSeverity}
        setOpenAlert={setOpenAlert}
      />
      <RecipientDialog
        openNewRecipientDialog={openNewRecipientDialog}
        handleClose={handleCloseInternalRecipientDialog}
        clients={clients}
        recipients={recipients}
        setAlertMessage={setAlertMessage}
        setSeverity={setSeverity}
        setOpenAlert={setOpenAlert}
        clientInfo={client}
      />

      <EditRecipientDialog
        openEditRecipientDialog={openEditRecipientDialog}
        handleClose={handleCloseInternalRecipientDialog}
        recipientInfo={recipientInfo}
        setAlertMessage={setAlertMessage}
        setSeverity={setSeverity}
        setOpenAlert={setOpenAlert}
      />
      <ProviderLimitDialog
        open={openProviderLimitDialog}
        handleClose={handleCloseProviderLimitDialog}
      />
    </Box>
  );
};

export default NewTransaction;
